import styled from "styled-components";

export const Container = styled.button`
  :hover {
    border: 2px solid var(--orange);
    cursor: pointer;
  }
  width: 100%;
  box-shadow: rgb(0 0 0 / 10%) 1px 1px 8px;
  border-radius: 5px;
  background: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 1rem;
  margin-bottom: 16px;
  margin-right: 16px;
  height: 4.5rem;
  line-height: 1rem;
  text-align: center;
  font-size: 1.125rem;
  font-family: Roboto, sans-serif;
  color: rgb(153, 153, 153);
  cursor: pointer;
  border: 1px solid transparent;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;
