import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const PainIntensity = ({ setValue, watch }) => {
  const severity = watch('severity') || 0   
  
  const handle = (painIntensity, severityWeight) => {
    if (severityWeight > severity) {
      setValue('severity', severityWeight)
    }
    setValue('painIntensity', painIntensity)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }
  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Qual é a intensidade da dor?</h2>
          <div>
            <Button type='button' onClick={() => handle('Muito forte', 4)}>
              Pior dor que já senti
            </Button>
            <Button type='button' onClick={() => handle('Media', 3)}>
              Dor média
            </Button>
            <Button type='button' onClick={() => handle('Fraca', 2)}>
              Dor fraca
            </Button>
            <Button type='button' onClick={() => handle('Nenhuma acima', 0)}>
              Nenhuma acima
            </Button>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}

export default PainIntensity
