import AnyOtherSymptom from '../../SharedQuestions/AnyOtherSymptom'
import BellyPainLocation from '../../SharedQuestions/BellyPainLocation'
import ChronicDisease from '../../SharedQuestions/ChronicDisease'
import ExtraSymptoms from '../../SharedQuestions/ExtraSymptoms'
import Fever from '../../SharedQuestions/Fever'
import HowAreFezes from '../../SharedQuestions/HowAreFezes'
import HowWasBlood from '../../SharedQuestions/HowWasBlood'
import KindOfBellyPain from '../../SharedQuestions/KindOfBellyPain'
import NauseaOrVomit from '../../SharedQuestions/NauseaOrVomit'
import PainIntensity from '../../SharedQuestions/PainIntensity'
import Smoker from '../../SharedQuestions/Smoker'
import UrineDifficulty from '../../SharedQuestions/UrineDifficulty'
import WasThereBlood from '../../SharedQuestions/WasThereBlood'
import WhenSymptomsStarted from '../../SharedQuestions/WhenSymptomsStarted'
import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const DiarrheaProtocol = ({
  setValue,
  onSubmit,
  getValues,
  watch,
  isLoading,
  setIsLoading,
}) => {


  const fezes = watch('fezes')
  const when = watch('whenSymptomsStarted') || false
  const dailyDiarrhea = watch('dailyDiarrhea') || false
  const thirsty = watch('thirsty') || false
  const bellyPain = watch('bellyPain') || false
  const pain = watch('infBellyPainLocation') || false
  const kindOfBellyPain = watch('kindOfBellyPain') || false
  const painIntensity = watch('painIntensity')
  const fever = watch('fever')
  const nausea = watch('nausea')
  const vomit = watch('vomit')
  const blood = watch('blood')
  const howWasBlood = watch('kindOfBlood')
  const urineDifficulty = watch('urineDifficulty')
  const food = watch('food')
  const antibiotic = watch('antibiotic')
  const weight = watch('weight')
  const anotherSymptom = watch('anotherSymptom')
  const extraSymptomsAwnser = watch('extraSymptomsAwnser')
  const smoker = watch('smoker')
  const severity = watch('severity') || 0

  const handle = (key, value, weight) => {
    if (weight > severity) {
      setValue('severity', weight)
    }
    setValue(key, value)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  return (
    <>
      <HowAreFezes setValue={setValue} watch={watch} />
      {fezes && <WhenSymptomsStarted setValue={setValue} />}
      {when && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Quantos episódios de dia de diarréia você tem por dia?</h2>
              <div>
                <Button
                  type='button'
                  onClick={() => handle('dailyDiarrhea', 'Menos de 5', 2)}
                >
                  Menos do que cinco episódios
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('dailyDiarrhea', 'Mais de 6', 4)}
                >
                  Seis ou mais episódios
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {dailyDiarrhea && <UrineDifficulty setValue={setValue} watch={watch} />}
      {urineDifficulty && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você está com mais sede do que o habitual?</h2>
              <div>
                <Button
                  type='button'
                  onClick={() => handle('thirsty', 'Sim, mais sede', 3)}
                >
                  Sim, estou com mais sede do que o normal
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('thirsty', 'Não, sem sede', 2)}
                >
                  Não, estou sem sede
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('thirsty', 'Nenhum acima', 0)}
                >
                  Nenhum acima
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {thirsty && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você tem dor abdominal?</h2>
              <div>
                <Button
                  type='button'
                  onClick={() => handle('bellyPain', 'Sim', 0)}
                >
                  Sim, minha barriga dói
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('bellyPain', 'Não', 0)}
                >
                  Não, estou sem dor na barriga
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {bellyPain && <BellyPainLocation setValue={setValue}/>}

      {/* no belly pain pula está pergunta */}
      {pain && <WhenSymptomsStarted setValue={setValue} watch={watch} />}
      {when && pain && <KindOfBellyPain setValue={setValue} />}
      {kindOfBellyPain && <PainIntensity setValue={setValue} watch={watch} />}
      {painIntensity && <NauseaOrVomit setValue={setValue} watch={watch} />}
      {vomit && <WasThereBlood setValue={setValue} watch={watch}/>}
      {blood === 'Sim' && <HowWasBlood setValue={setValue} />}
      {(nausea && <Fever setValue={setValue} watch={watch} />) ||
        (howWasBlood && <Fever setValue={setValue}  watch={watch}/>)}
      {fever && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você se alimentou de comida de rua ou mal passada?</h2>
              <div>
                <Button type='button' onClick={() => handle('food', 'Sim', 3)}>
                  Sim, comi uma comida de rua ou mal passada
                </Button>
                <Button type='button' onClick={() => handle('food', 'Não', 3)}>
                  Não, não mudei meu hábito alimentar
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {food && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você usou antibióticos nos últimos 2 meses?</h2>
              <div>
                <Button
                  type='button'
                  onClick={() => handle('antibiotic', 'Sim', 3)}
                >
                  Sim, usei recentemente
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('antibiotic', 'Não', 3)}
                >
                  Não, não usei recentemente
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {antibiotic && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você ganhou ou perdeu peso recentemente?</h2>
              <div>
                <Button
                  type='button'
                  onClick={() => handle('weight', 'Ganhei peso', 3)}
                >
                  Ganhei peso
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('weight', 'Perdi peso', 3)}
                >
                  Perdi peso
                </Button>
                <Button type='button' onClick={() => handle('weight', 'Não', 3)}>
                  Não
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {weight && <AnyOtherSymptom setValue={setValue} watch={watch} />}
      {(anotherSymptom === 'Sim' && <ExtraSymptoms setValue={setValue} />) ||
        (anotherSymptom === 'Não' && <Smoker setValue={setValue} />)}
      {extraSymptomsAwnser && <Smoker setValue={setValue} />}
      {smoker && (
        <ChronicDisease
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          onSubmit={onSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  )
}

export default DiarrheaProtocol
