import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  header {
    img {
      height: 80px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  header {
    height: 103px;
    width: 60%;
  }
  #confirm {
    width: 60%;
  }
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px)        
    }
    to {
        opacity: 1;
        transform: translateX(0px)
    }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${appearFromLeft} 1s;
  background-color: var(--orange);
  width: 100vw;
  padding: 28px 0;
  height: 100vh;

  div {
    width: 40%;
    background-color: var(--${(severity) => severity.children?.props.severity});
    display: inline;
    font-size: 18px;
    line-height: 1.5rem;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    padding: 34px;
    border: 2px solid #fff;
    border-radius: 16px;
    h5 {
      font-size: 36px;
      font-family: "Roboto";
      font-weight: 700;
      line-height: 2rem;
      margin: 0 0 16px;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      width: 80%;
      span {
        font-size: 0.75rem;
      }
      button {
        width: 100%;
      }
    }
  }
`;
