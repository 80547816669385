import styled, { keyframes } from "styled-components";

const scaleAnimation = keyframes`
from {
    opacity: 0;
    width: 0;
    height: 0;
}
to {
    opacity: 1;
    width: 3em;
    height: 3em;
}
`;

const spinnerAnimation = keyframes`
from {
    transform: rotate(0deg)
}
to {
    transform: rotate(360deg)
}`;

export const Container = styled.div`
padding: 23px;
display: flex;
align-items: center;
max-height: fit-content;
width: 100%;
color: inherit;
background: none !important;
border: none !important;
box-shadow: none !important;   
    span {
        display: inline-block;
        width: 3em;
        height: 3em;
        border: .14em solid #F6911E;
        border-bottom-color: transparent;
        border-radius: 100%;
        animation: ${spinnerAnimation} .8s ease infinite, ${scaleAnimation} .1s linear;
    }
}
`;
