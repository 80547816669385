import { useCallback, useState } from "react";

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => setIsOpen(true), []);

  const closeModal = useCallback(() => setIsOpen(false), []);

  const changeOpen = useCallback((open) => setIsOpen(open), []);

  return { isOpen, openModal, closeModal, changeOpen };
}
