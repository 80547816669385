import React from 'react'
import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const QuestionsOrInfo = ({ setValue }) => {
  const handle = (questions) => {
    setValue('questionsOrInfo', questions)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }
  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>
            Se você quiser apenas esclarecer dúvidas ou ter informações de
            coleta de exames, agendamento, resultado de exames e outros,
            responda:
          </h2>
          <div>
            <Button type='button' onClick={() => handle('Não')}>
              Não, quero passar pela triagem para atendimento
            </Button>
            <Button type='button' onClick={() => handle('Sim')}>
              Sim, quero apenas tirar dúvidas
            </Button>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}

export default QuestionsOrInfo
