import styled from "styled-components";

export const Container = styled.button`
  background: ${(props) => (props.whiteSchema ? "#f5f5f5" : "#F6911E")};
  color: ${(props) => (props.whiteSchema ? "#f9f9f9" : "#f5f5f5")};
  border-radius: 30px;
  transition: 0.5s;
  :hover {
    border: 2px solid var(--orange);
    cursor: pointer;
  }
  width: 260px;
  font-size: 1rem;
  font-weight: 300;
  height: 3.25rem;
  line-height: 3.25rem;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  border: 0px;
  margin-top: 20px;
`;
