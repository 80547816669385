import Button from "../../components/Button";
import ButtonConfirm from "../../components/ButtonConfirm";
import { AnimationContainer, Container, Content } from "./styles";


const FormAssociatedSymptom = ({ setValue }) => {
    const handle = (symp) => {
        setValue("associatedSymptom", symp)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }

    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Entendi. E você apresenta algum outro sintoma associado? Marque abaixo, por favor:
                    </h2>
                    <div>
                        <Button type="button" onClick={() => handle("Febre")}>
                            Febre
                        </Button>
                        <Button type="button" onClick={() => handle("Dor nas Articulações")}>
                            Dor nas articulações (juntas)
                        </Button>
                        <Button type="button" onClick={() => handle("Dor nas costas")}>
                            Dor nas costas
                        </Button>
                        <Button type="button" onClick={() => handle("Dor e/ou inchaço nas pernas")}>
                            Dor e/ou inchaço nas pernas
                        </Button>
                        <Button type="button" onClick={() => handle("Manchas, vesículas ou coceira pelo corpo")}>
                            Manchas, vesículas ou coceira pelo corpo
                        </Button>
                        <Button type="button" onClick={() => handle("Cansaço maior que o habitual")}>
                            Cansaço maior que o habitual
                        </Button>
                        <Button type="button" onClick={() => handle("Olho vermelho")}>
                            Olho vermelho
                        </Button>
                        <Button type="button" onClick={() => handle("Dor muscular")}>
                            Dor muscular
                        </Button>
                        <Button type="button" onClick={() => handle("Vertigem e tontura")}>
                            Vertigem e tontura
                        </Button>
                        <Button type="button" onClick={() => handle("Secreção vaginal")}>
                            Secreção vaginal
                        </Button>
                        <Button type="button" onClick={() => handle("Dificuldade para enxergar")}>
                            Dificuldade para enxergar
                        </Button>
                    </div>
                        <ButtonConfirm type="button" onClick={() => handle("Nenhum acima")}>
                            Nenhum acima
                        </ButtonConfirm>
                </AnimationContainer>
            </Content>
        </Container>
    )
};


export default FormAssociatedSymptom;