import { useState } from 'react'
import ButtonConfirm from '../../components/ButtonConfirm'
import { Checkbox } from '../../components/Checkbox'
import Alcohol from '../../SharedQuestions/Alcohol'
import ChronicDisease from '../../SharedQuestions/ChronicDisease'
import EvacuationDifficulty from '../../SharedQuestions/EvacuationDifficulty'
import Fever from '../../SharedQuestions/Fever'
import HowAreFezes from '../../SharedQuestions/HowAreFezes'
import HowPainStarted from '../../SharedQuestions/HowPainStarted'
import HowWasBlood from '../../SharedQuestions/HowWasBlood'
import KindOfBellyPain from '../../SharedQuestions/KindOfBellyPain'
import NauseaOrVomit from '../../SharedQuestions/NauseaOrVomit'
import PainCharacteristic from '../../SharedQuestions/PainCharacteristic'
import PainIntensity from '../../SharedQuestions/PainIntensity'
import Smoker from '../../SharedQuestions/Smoker'
import SpecificMedicine from '../../SharedQuestions/SpecificMedicine'
import WasThereBlood from '../../SharedQuestions/WasThereBlood'
import WhenSymptomsStarted from '../../SharedQuestions/WhenSymptomsStarted'
import Worsening from '../../SharedQuestions/Worsening'
import { AnimationContainer, Container, Content } from './styles'

const SuperiorBelly = ({
  setValue,
  onSubmit,
  getValues,
  watch,
  isLoading,
  setIsLoading,
}) => {
  const [painLocation, setPainLocation] = useState([])
  const [liver, setLiver] = useState([])

  const pain = watch('painLocation') || false
  const when = watch('whenSymptomsStarted') || false
  const painCharacteristic = watch('painCharacteristic') || false
  const kindOfBellyPain = watch('kindOfBellyPain') || false
  const howPainStarted = watch('howPainStarted')
  const worsening = watch('bellyPainWorsening')
  const painIntensity = watch('painIntensity')
  const fever = watch('fever')
  const nausea = watch('nausea')
  const vomit = watch('vomit')
  const blood = watch('blood')
  const howWasBlood = watch('kindOfBlood')
  const evacuationDifficulty = watch('evacuationDifficulty')
  const fezes = watch('fezes')
  const alcohol = watch('alcohol')
  const specificMedicine = watch('specificMedicine')
  const smoker = watch('smoker')
  const liverDisease = watch('liver')

  const handle = (key, value) => {
    setValue(key, value)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  const checkBoxPain = (e, v) => {
    if (e.target?.checked) {
      setPainLocation((state) => [...state, v])
    } else {
      setPainLocation((state) => state.filter((e) => e !== v))
    }
  }

  const checkBoxLiver = (e, v) => {
    if (e.target?.checked) {
      setLiver((state) => [...state, v])
    } else {
      setLiver((state) => state.filter((e) => e !== v))
    }
  }

  return (
    <>
      <Container>
        <Content>
          <AnimationContainer>
            <h2>Qual é o local da dor?</h2>
            <div>
              <Checkbox
                id='bellyPainLocation-1'
                label='Superior esquerdo'
                onChange={(e) => checkBoxPain(e, 'Superior esquerdo')}
              />
              <Checkbox
                id='bellyPainLocation-2'
                label='Superior direito'
                onChange={(e) => checkBoxPain(e, 'Superior direito')}
              />
              <Checkbox
                id='bellyPainLocation-3'
                label='Superior centro'
                onChange={(e) => checkBoxPain(e, 'Superior centro')}
              />
            </div>
            <ButtonConfirm
            type='button'
            onClick={() => {
                if (painLocation.length > 0)
                handle('painLocation', painLocation)
            }}
            >
            Confirmar
            </ButtonConfirm>
          </AnimationContainer>
        </Content>
      </Container>
      {pain && <WhenSymptomsStarted setValue={setValue} />}
      {when && <PainCharacteristic setValue={setValue} />}
      {painCharacteristic && <KindOfBellyPain setValue={setValue} />}
      {kindOfBellyPain && <HowPainStarted setValue={setValue} watch={watch} belly={true} />}
      {howPainStarted && <Worsening setValue={setValue} />}
      {worsening && <PainIntensity setValue={setValue} watch={watch}/>}
      {painIntensity && <Fever setValue={setValue} watch={watch} />}
      {fever && <NauseaOrVomit setValue={setValue} watch={watch} />}
      {vomit && <WasThereBlood setValue={setValue} watch={watch} />}
      {blood === 'Sim' && <HowWasBlood setValue={setValue} />}
      {(nausea && <EvacuationDifficulty setValue={setValue} belly={true} watch={watch} />) ||
        (howWasBlood && <EvacuationDifficulty setValue={setValue} belly={true} watch={watch}/>)}
      {evacuationDifficulty && <HowAreFezes setValue={setValue} watch={watch} belly={true}/>}
      {fezes && <Alcohol setValue={setValue} />}
      {alcohol && <SpecificMedicine setValue={setValue} watch={watch} supbelly={true} />}
      {specificMedicine && <Smoker setValue={setValue} />}
      {smoker && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Possui alguma das doenças indicadas abaixo?</h2>
              <div>
                <Checkbox
                  id='liverDisease-1'
                  label='Hepatite A'
                  onChange={(e) => checkBoxLiver(e, 'Hepatite A')}
                />
                <Checkbox
                  id='liverDisease-2'
                  label='Hepatite B'
                  onChange={(e) => checkBoxLiver(e, 'Hepatite B')}
                />
                <Checkbox
                  id='liverDisease-3'
                  label='Hepatite C'
                  onChange={(e) => checkBoxLiver(e, 'Hepatite C')}
                />
                <Checkbox
                  id='liverDisease-4'
                  label='Síndrome de Gilbert'
                  onChange={(e) => checkBoxLiver(e, 'Síndrome de Gilbert')}
                />
                <Checkbox
                  id='liverDisease-5'
                  label='Esteatose Hepática'
                  onChange={(e) => checkBoxLiver(e, 'Esteatose Hepática')}
                />
                <Checkbox
                  id='liverDisease-6'
                  label='Cirrose Biliar Primária'
                  onChange={(e) => checkBoxLiver(e, 'Cirrose Biliar Primária')}
                />
                <Checkbox
                  id='liverDisease-7'
                  label='Câncer hepático'
                  onChange={(e) => checkBoxLiver(e, 'Câncer hepático')}
                />
                <Checkbox
                  id='liverDisease-7'
                  label='Não'
                  onChange={(e) => checkBoxLiver(e, 'Não')}
                />
                <ButtonConfirm
                  type='button'
                  onClick={() => {
                    if (liver.length > 0) handle('liver', liver)
                  }}
                >
                  Confirmar
                </ButtonConfirm>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {liverDisease && (
        <ChronicDisease
        watch={watch}
          setValue={setValue}
          getValues={getValues}
          onSubmit={onSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  )
}

export default SuperiorBelly
