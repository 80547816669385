import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
`;

export const ModalContainer = styled.div`
  @media (max-width: 767px) {
    img {
      height: 29px !important;
      margin-top: 31px !important;
      margin-left: 35px !important;
    }
    h3 {
      color: #1b255e !important;
      font-size: 24px !important;
      font-family: "Roboto", sans-serif !important;
      font-weight: 900 !important;
      line-height: 1.75rem !important;
      margin-top: 20px !important;
      margin-bottom: 10px !important;
    }
    p {
      font-size: 16px !important;
      font-family: "Roboto", sans-serif !important;
      font-weight: 500 !important;
      margin-bottom: 15px !important;
    }
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px)        
    }
    to {
        opacity: 1;
        transform: translateX(0px)
    }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${appearFromLeft} 1s;
  width: 60%;

  #sendForm {
    align-self: center;
  }

  h2 {
    margin: 0 0 29px;
  }
  div {
    display: grid;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 100vh;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      div {
        width: 100%;
      }
      span {
        font-size: 0.75rem;
      }
      button {
        width: 100%;
      }
    }
  }
`;
