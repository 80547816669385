import Button from "../../components/Button";
import ButtonConfirm from "../../components/ButtonConfirm";
import { AnimationContainer, Container, Content } from "./styles";


const HowSymptomsStarted = ({ setValue, watch }) => {
    const severity = watch('severity') || 0
    const handle = (howSymptomsStarted, weight) => {
        if (weight > severity) {
            setValue('severity', weight)
        }
        setValue("howSymptomsStarted", howSymptomsStarted)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }

    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Como seus sintomas começaram?
                    </h2>
                    <div>

                        <Button type="button" onClick={() => handle("Abruptamente, de uma hora para outra", 3)}>Abruptamente, de uma hora para outra</Button>
                        <Button type="button" onClick={() => handle("Progressivamente em alguns dias", 3)}>Progressivamente em alguns dias</Button>
                        {/* flag azul */}
                        <Button type="button" onClick={() => handle("Progressivamente em algumas semanas", 2)}>Progressivamente em algumas semanas</Button>
                        {/* cinza? */}
                        <Button type="button" onClick={() => handle("Progressivamente em alguns meses", 0)}>Progressivamente em alguns meses</Button>
                        <ButtonConfirm type="button" onClick={() => handle("Nenhuma acima", 0)}>Nenhuma acima</ButtonConfirm>
                    </div>
                </AnimationContainer>
            </Content>

        </Container>
    )
};

export default HowSymptomsStarted;