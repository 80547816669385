import Button from "../../components/Button";
import { AnimationContainer, Container, Content } from "./styles";


const Smoker = ({ setValue }) => {
    const handle = (smoker) => {
        setValue("smoker", smoker)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Você é fumante?
                    </h2>
                    <div>
                    <Button type="button" onClick={() => handle("Sim")}>Sim</Button>
                    <Button type="button" onClick={() => handle("Ex-fumante")}>Ex-fumante</Button>
                    <Button type="button" onClick={() => handle("Não")}>Não, nunca tive o hábito de fumar</Button>   
                    </div>
                </AnimationContainer>
            </Content>

        </Container>
    )
};


export default Smoker;