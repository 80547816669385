import Button from "../../components/Button";
import { AnimationContainer, Container, Content } from "./styles";


const Pain = ({ setValue, watch }) => {
    const severity = watch('severity') || 0
    const handle = (painUnconfort, weight) => {
        if (weight > severity) {
            setValue('severity', weight)
        }
        setValue("painUnconfort", painUnconfort)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }
    // severity blue
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Quanto de desconforto ou dor esses sintomas trazem para você?
                    </h2>
                    {/* blue */}
                    <div>
                        <Button type="button" onClick={() => handle("Nenhum desconforto", 2)}>Nenhum desconforto</Button>
                        {/* yellow */}
                        <Button type="button" onClick={() => handle("Leve desconforto", 3)}>Leve desconforto</Button>
                        <Button type="button" onClick={() => handle("Muito desconfortável", 4)}>Muito desconfortável</Button>
                        {/* red */}
                        <Button type="button" onClick={() => handle("Insuportável", 4)}>Insuportável</Button>
                    </div>
                </AnimationContainer>
            </Content>

        </Container>
    )
};


export default Pain;