import { useState } from 'react'
import Button from '../../components/Button'
import ButtonConfirm from '../../components/ButtonConfirm'
import { Checkbox } from '../../components/Checkbox'
import AnyOtherSymptom from '../../SharedQuestions/AnyOtherSymptom'
import ChronicDisease from '../../SharedQuestions/ChronicDisease'
import ExtraSymptoms from '../../SharedQuestions/ExtraSymptoms'
import Fever from '../../SharedQuestions/Fever'
import HowPainStarted from '../../SharedQuestions/HowPainStarted'
import Impact from '../../SharedQuestions/Impact'
import Medicine from '../../SharedQuestions/Medicine'
import Pain from '../../SharedQuestions/Pain'
import Smoker from '../../SharedQuestions/Smoker'
import { AnimationContainer, Container, Content } from './styles'

const ThroatProtocol = ({
  setValue,
  onSubmit,
  getValues,
  watch,
  isLoading,
  setIsLoading,
}) => {
  const [throatPainLocation, setThroatPainLocation] = useState([])
  const [extraSymptomList, setExtraSymptomList] = useState([])
  const [earSymptomList, setEarSymptomList] = useState([])

  const throatPain = watch('throatPainLocation')
  const howPainStarted = watch('howPainStarted')
  const extraSymptom = watch('extraSymptom')
  const earSymptom = watch('earSymptom')
  const swollenThroat = watch('swollenThroat') || false
  const toSwallow = watch('toSwallow') || false
  const areSymptomsBetter = watch('areSymptomsBetter')
  const fever = watch('fever')
  const pain = watch('painUnconfort') || false
  const impact = watch('impact')
  const medicine = watch('medicine')
  const anotherSymptom = watch('anotherSymptom')
  const extraSymptomsAwnser = watch('extraSymptomsAwnser')
  const throatExtraSymptom = watch('throatExtraSymptom')
  const smoker = watch('smoker')
  // const noseSymptom = watch("congestion") || watch("runnyNose") || watch("sneezing") || watch("itching") || watch("noSmell")
  // const fluSymptoms = watch("fluSymptoms");
  // const travelled = watch("travelled");
  // const throatGroup = watch("groupIdentification");
  // const lackOfAir = watch("lackOfAir");
  const severity = watch('severity') || 0

  const handle = (key, value, weight) => {
    if (weight > severity) {
      setValue('severity', weight)
    }
    setValue(key, value)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  const checkBoxThroat = (e, v) => {
    if (e.target?.checked) {
      setThroatPainLocation((state) => [...state, v])
    } else {
      setThroatPainLocation((state) => state.filter((e) => e !== v))
    }
  }

  const checkBoxExtraSymptom = (e, v) => {
    if (e.target?.checked) {
      setExtraSymptomList((state) => [...state, v])
    } else {
      setExtraSymptomList((state) => state.filter((e) => e !== v))
    }
  }

  const checkBoxEarSymptom = (e, v) => {
    if (e.target?.checked) {
      setEarSymptomList((state) => [...state, v])
    } else {
      setEarSymptomList((state) => [...state, v])
    }
  }

  return (
    <>
      <Container>
        <Content>
          <AnimationContainer>
            <h2>
              Além da dor, você tem algum desses sintomas relacionados à garganta?
            </h2>
            <div>
              <Checkbox
                type='button'
                id='throatPainLocation-1'
                label='Garganta vermelha'
                onChange={(e) => checkBoxThroat(e, 'Garganta vermelha')}
              />
              <Checkbox
                type='button'
                id='throatPainLocation-2'
                label='Amígdala inchada'
                onChange={(e) => checkBoxThroat(e, 'Amígdala inchada')}
              />
              <Checkbox
                type='button'
                id='throatPainLocation-3'
                label='Sensação de "bola" na garganta'
                onChange={(e) =>
                  checkBoxThroat(e, 'Sensação de "bola" na garganta')
                }
              />
            </div>
            <ButtonConfirm
            type='button'
            onClick={() => {
                if (throatPainLocation.length > 0)
                handle('throatPainLocation', throatPainLocation, 0)
            }}
            >
            Confirmar
            </ButtonConfirm>
          </AnimationContainer>
        </Content>
      </Container>
      {throatPain && <HowPainStarted setValue={setValue} watch={watch}/>}
      {howPainStarted && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você tem alguma destas queixas relacionadas?</h2>
              <div>
                <Checkbox
                  type='button'
                  id='extraSymptomN-1'
                  label='Congestão nasal (nariz entupido)'
                  onChange={(e) =>
                    checkBoxExtraSymptom(e, 'Congestão nasal (nariz entupido)')
                  }
                />
                <Checkbox
                  type='button'
                  id='extraSymptomN-2'
                  label='Coriza nasal (nariz escorrendo)'
                  onChange={(e) =>
                    checkBoxExtraSymptom(e, 'Coriza nasal (nariz escorrendo)')
                  }
                />
                <Checkbox
                  type='button'
                  id='extraSymptomN-3'
                  label='Espirrando mais do que o habitual'
                  onChange={(e) =>
                    checkBoxExtraSymptom(e, 'Espirrando mais do que o habitual')
                  }
                />
                <Checkbox
                  type='button'
                  id='extraSymptomN-4'
                  label='Coceira no nariz'
                  onChange={(e) => checkBoxExtraSymptom(e, 'Coceira no nariz')}
                />
                <Checkbox
                  type='button'
                  id='extraSymptomN-5'
                  label='Não sinto o cheiro das coisas'
                  onChange={(e) =>
                    checkBoxExtraSymptom(e, 'Não sinto o cheiro das coisas')
                  }
                />
              </div>
            <ButtonConfirm
                type='button'
                onClick={() => {
                if (extraSymptomList.length > 0)
                    handle('extraSymptomsAwnser', extraSymptomList, 0)
                }}
            >
                Confirmar
            </ButtonConfirm>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {/* Adicionar fluxo do covid */}
      {extraSymptomsAwnser && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você tem alguma queixa relacionada ao ouvido?</h2>
              <div>
              <Checkbox
                type='button'
                id='earSymptom-1'
                label='Dor de ouvido'
                onChange={(e) => checkBoxEarSymptom(e, 'Dor de ouvido')}
                />
              <Checkbox
                type='button'
                id='earSymptom-2'
                label='Saída de secreção pelo ouvido'
                onChange={(e) =>
                  checkBoxEarSymptom(e, 'Saída de secreção pelo ouvido')
                }
                />
              <Checkbox
                type='button'
                id='earSymptom-3'
                label='Sensação de tamponamento no ouvido'
                onChange={(e) =>
                  checkBoxEarSymptom(e, 'Sensação de tamponamento no ouvido')
                }
                />
              <Checkbox
                type='button'
                id='earSymptom-4'
                label='Excesso de cera no ouvido'
                onChange={(e) =>
                  checkBoxEarSymptom(e, 'Excesso de cera no ouvido')
                }
                />
            </div>
              <ButtonConfirm
                type='button'
                onClick={() => {
                  if (earSymptomList.length > 0)
                    handle('earSymptom', earSymptomList, 0)
                }}
              >
                Confirmar
              </ButtonConfirm>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {earSymptom && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>
                Você notou inchaço nas ínguas do pescoço (linfonodomegalia)?
              </h2>
              <div>

              <Button
                type='button'
                onClick={() => handle('swollenThroat', 'Sim', 0)}
                >
                Sim, notei linfonodomegalia
              </Button>
              <Button
                type='button'
                onClick={() => handle('swollenThroat', 'Não', 0)}
                >
                Não, não notei inchaço no pescoço
              </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {swollenThroat && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você tem dificuldade para engolir?</h2>
              <div>

              <Button
                type='button'
                onClick={() => handle('toSwallow', 'Sim, dificuldade em engolir alimentos sólidos e líquidos', 4)}
                >
                Sim, dificuldade em engolir alimentos sólidos e líquidos
              </Button>
              <Button
                type='button'
                onClick={() => handle('toSwallow', 'Sim, dificuldade em engolir somente alimentos sólidos', 4)}
                >
                Sim, dificuldade em engolir somente alimentos sólidos
              </Button>
              <Button
                type='button'
                onClick={() => handle('toSwallow', 'Não tenho dificuldade em engolir', 2)}
                >
                Não tenho dificuldade em engolir
              </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {toSwallow && <Fever setValue={setValue} watch={watch} />}
      {fever && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Como você acha que evoluíram os sintomas?</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'areSymptomsBetter',
                      'Acho que os sintomas estão melhores',
                      2
                    )
                  }
                >
                  Acho que os sintomas estão melhores
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'areSymptomsBetter',
                      'Acho que os sintomas estão iguais',
                      3
                    )
                  }
                >
                  Acho que os sintomas estão iguais
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'areSymptomsBetter',
                      'Acho que os sintomas estão piores',
                      4
                    )
                  }
                >
                  Acho que os sintomas estão piores
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {areSymptomsBetter && <Pain setValue={setValue} watch={watch} />}
      {pain && <Impact setValue={setValue} watch={watch} />}
      {impact && <Medicine setValue={setValue} watch={watch} />}
      {medicine && <AnyOtherSymptom setValue={setValue} watch={watch} />}
      {anotherSymptom === 'Sim' && <ExtraSymptoms setValue={setValue} />}
      {throatExtraSymptom && <Smoker setValue={setValue} />}
      {(anotherSymptom === 'Não' && <Smoker setValue={setValue} />)}
      {smoker && (
        <ChronicDisease
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          onSubmit={onSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  )
}

export default ThroatProtocol
