import * as Dialog from "@radix-ui/react-dialog";
import { animated, useTransition } from "@react-spring/web";
import React, {
  createContext,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { MdClose } from "react-icons/md";
import { useControllableModal } from "./useControllableModal";

export const ModalContext = createContext({
  contentRef: null,
});

export const Modal = forwardRef(
  (
    {
      isOpen: isOpenProp,
      onChangeOpen,
      size,
      children,
      trigger,
      title,
      closeButton,
    },
    ref
  ) => {
    const { isOpen, setIsModalOpen, ...modalHandles } = useControllableModal({
      prop: isOpenProp,
      onChangeOpen,
      defaultProp: false,
    });

    const springs = useTransition(isOpen, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { duration: 100 },
    });

    const heightStyle = size?.height && {
      height: "62vh",
      maxHeight: size?.height,
    };

    const contentRef = useRef(null);
    const modalContextData = useMemo(() => ({ contentRef }), []);

    useImperativeHandle(ref, () => ({
      ...modalHandles,
      setIsModalOpen,
      isOpen: Boolean(isOpen),
    }));

    return (
      <ModalContext.Provider value={modalContextData}>
        <Dialog.Root open={isOpen} onOpenChange={setIsModalOpen}>
          <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
          {springs(
            (styleSpring, open) =>
              open && (
                <Dialog.Portal forceMount>
                  <animated.div
                    style={{
                      ...styleSpring,
                    }}
                  >
                    <Dialog.Overlay style={{position: "fixed", background: "rgb(0,0,0,0.6)", width: "fit-screen", height: "fit-screen", top: "0", left: "0", right: "0", bottom: "0", zIndex: "50"}} />
                    <Dialog.Content
                      ref={contentRef}
                      style={{
                        padding: "20px", 
                        position:  "fixed", 
                        zIndex: "9999", 
                        top: "50%", 
                        left: "50%", 
                        transform: "translate(-50%,-50%)", 
                        maxHeigth: "85vh", 
                        background: "#FFF", 
                        borderRadius: "12px", 
                        display: "flex", 
                        flexDirection: "col",
                      }}
                    >
                      <div style={{marginBottom: "20px", display: "flex", flex: "0 1 auto", flexDirection: "row", justifyContent: "flex-end", gap: "8px", alignItems: "flex-start", position: "relative"}}>
                        {title && (
                          <Dialog.Title
                            style={{textAlign: "left", flex: "1 1 0%", color: "#000"}}
                          >
                            {title}
                          </Dialog.Title>
                        )}
                        {/* {closeButton ? (
                          <Dialog.DialogClose
                          >
                            <MdClose />
                          </Dialog.DialogClose>
                        ) : null} */}
                      </div>

                      <div
                        style={{
                          width: "70vw",
                          maxWidth: size?.width || "62.875rem",
                          ...heightStyle,
                        }}
                      >
                        {children}
                      </div>
                    </Dialog.Content>
                  </animated.div>
                </Dialog.Portal>
              )
          )}
        </Dialog.Root>
      </ModalContext.Provider>
    );
  }
);

Modal.displayName = "Modal";

Modal.defaultProps = {
  closeButton: true,
};
