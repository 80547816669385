import Button from "../../components/Button";
import { AnimationContainer, Container, Content } from "./styles";

const Impact = ({ setValue, watch }) => {
    const severity = watch('severity') || 0
    const handle = (impact, weight) => {
        if (weight > severity) {
            setValue('severity', weight)
        }
        setValue("impact", impact)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }
    // blue
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Qual é o impacto dos seus sintomas no dia a dia?
                    </h2>
                    {/* blue */}
                    <div>
                        <Button type="button" onClick={() => handle("Sem impacto no dia a dia", 2)}>Sem impacto no dia a dia</Button>
                        {/* yellow */}
                        <Button type="button" onClick={() => handle("Incômodo pequeno nas atividades diárias", 3)}>Incômodo pequeno nas atividades diárias</Button>
                        <Button type="button" onClick={() => handle("Importante limitação das atividades diárias", 4)}>Importante limitação das atividades diárias</Button>
                        {/* red */}
                        <Button type="button" onClick={() => handle("Impossível de realizar as atividades diárias", 4)}>Impossível de realizar as atividades diárias</Button>
                    </div>
                </AnimationContainer>
            </Content>

        </Container>
    )
};

export default Impact;