import { useState } from 'react'
import Button from '../../components/Button'
import ButtonConfirm from '../../components/ButtonConfirm'
import { Checkbox } from '../../components/Checkbox'
import AnyOtherSymptom from '../../SharedQuestions/AnyOtherSymptom'
import ChronicDisease from '../../SharedQuestions/ChronicDisease'
import ExtraSymptoms from '../../SharedQuestions/ExtraSymptoms'
import Fever from '../../SharedQuestions/Fever'
import GroupIdentification from '../../SharedQuestions/GroupIdentification'
import HowPainStarted from '../../SharedQuestions/HowPainStarted'
import Impact from '../../SharedQuestions/Impact'
import Medicine from '../../SharedQuestions/Medicine'
import Pain from '../../SharedQuestions/Pain'
import PainIntensity from '../../SharedQuestions/PainIntensity'
import WhenSymptomsStarted from '../../SharedQuestions/WhenSymptomsStarted'
import { AnimationContainer, Container, Content } from './styles'

const EarProtocol = ({
  setValue,
  watch,
  getValues,
  isLoading,
  setIsLoading,
  onSubmit,
}) => {
  const pain = watch('earPainLocation') || false
  const extraSymptom = watch('extraEarSymptom')
  const howIsHearing = watch('howIsHearing')
  const when = watch('whenSymptomsStarted') || false
  const howPainStarted = watch('howPainStarted')
  const painIntensity = watch('painIntensity')
  const fever = watch('fever')
  const areSymptomsBetter = watch('areSymptomsBetter')
  const painUnconfort = watch('painUnconfort')
  const impact = watch('impact')
  const medicine = watch('medicine')
  const anotherSymptom = watch('anotherSymptom')
  const extraSymptomsAwnser = watch('extraSymptomsAwnser')
  const earGroup = watch('groupIdentification')
  const severity = watch('severity') || 0

  const [extraEarSymptom, setExtraEarSymptom] = useState([])

  const handle = (key, value) => {
    if 
    (((key === 'extraEarSymptom') 
    && (value.includes('Secreção saindo do ouvido')))
    || (value.includes('Orelha vermelha')) 
    || (value.includes('Orelha inchada'))) {
      if (severity < 3) {
        setValue('severity', 3)
      }
    }
    setValue(key, value)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  const handleWeight = (key, value, weight) => {
    if (weight > severity) {
      setValue('severity', 3)
    }
    setValue(key, value)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  const checkBoxState = (e, v) => {
    if (e.target?.checked) {
      setExtraEarSymptom((state) => [...state, v])
    } else {
      setExtraEarSymptom((state) => state.filter((e) => e !== v))
    }
  }

  return (
    <>
      <Container>
        <Content>
          <AnimationContainer>
            <h2>A dor de ouvido é de um ou dois lados?</h2>
            <div>
              <Button
                type='button'
                onClick={() => handle('earPainLocation', 'Lado esquerdo')}
              >
                A dor de ouvido é somente do lado esquerdo
              </Button>
              <Button
                type='button'
                onClick={() => handle('earPainLocation', 'Lado Direito')}
              >
                A dor de ouvido é somente do lado direito
              </Button>
              <Button
                type='button'
                onClick={() => handle('earPainLocation', 'Ambos os ouvidos')}
              >
                Tenho dos nos dois ouvidos
              </Button>
            </div>
          </AnimationContainer>
        </Content>
      </Container>
      {pain && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>
                Além da dor, você tem algum desses sintomas relacionados ao ouvido?
              </h2>
              <div>
                <Checkbox
                  type='button'
                  id='extraEarSymptom-1'
                  label='Secreção saindo do ouvido'
                  onChange={(e) =>
                    checkBoxState(e, 'Secreção saindo do ouvido')
                  }
                />
                <Checkbox
                  type='button'
                  id='extraEarSymptom-2'
                  label='Ouvido tampado'
                  onChange={(e) => checkBoxState(e, 'Ouvido tampado')}
                />
                <Checkbox
                  type='button'
                  id='extraEarSymptom-3'
                  label='Excesso de cera no ouvido'
                  onChange={(e) =>
                    checkBoxState(e, 'Excesso de cera no ouvido')
                  }
                />
                <Checkbox
                  type='button'
                  id='extraEarSymptom-4'
                  label='Coceira no ouvido'
                  onChange={(e) => checkBoxState(e, 'Coceira no ouvido')}
                />
                <Checkbox
                  type='button'
                  id='extraEarSymptom-5'
                  label='Orelha inchada'
                  onClick={() => handle('extraEarSymptom', 'Orelha inchada')}
                />
                <Checkbox
                  type='button'
                  id='extraEarSymptom-6'
                  label='Orelha vermelha'
                  onClick={() => handle('extraEarSymptom', 'Orelha vermelha')}
                />
              </div>
                <ButtonConfirm
                  type='button'
                  onClick={() => {
                    if (extraEarSymptom.length > 0)
                      handle('extraEarSymptom', extraEarSymptom)
                  }}
                >
                  Confirmar
                </ButtonConfirm>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {extraSymptom && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>E como está para escutar?</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'howIsHearing',
                      'Não escuto direito desde que começaram os sintomas'
                    )
                  }
                >
                  Não escuto direito desde que começaram os sintomas
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'howIsHearing',
                      'Escuto um zumbido desde que começaram os sintomas'
                    )
                  }
                >
                  Escuto um zumbido desde que começaram os sintomas
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('howIsHearing', 'Normal')}
                >
                  Normal
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('howIsHearing', 'Outros')}
                >
                  Outros
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {howIsHearing && <WhenSymptomsStarted setValue={setValue} />}
      {when && <HowPainStarted setValue={setValue} watch={watch}/>}
      {howPainStarted && <PainIntensity setValue={setValue} watch={watch} />}
      {painIntensity && <Fever setValue={setValue} watch={watch} />}
      {fever && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Como você acha que evoluíram os sintomas?</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handleWeight('areSymptomsBetter', 'A dor está melhor', 0)
                  }
                >
                  A dor está melhor
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handleWeight('areSymptomsBetter', 'A dor está igual', 3)
                  }
                >
                  A dor está igual
                </Button>
                <Button
                  type='button'
                  onClick={() => handleWeight('areSymptomsBetter', 'A dor está pior', 4)}
                >
                  A dor está pior
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {areSymptomsBetter && <Pain setValue={setValue} watch={watch} />}
      {painUnconfort && <Impact setValue={setValue} watch={watch}/>}
      {impact && <Medicine setValue={setValue} watch={watch} />}
      {medicine && <AnyOtherSymptom setValue={setValue} watch={watch} />}
      {anotherSymptom === 'Sim' && <ExtraSymptoms setValue={setValue} />}
      {(extraSymptomsAwnser && <GroupIdentification setValue={setValue} watch={watch} />) ||
        (anotherSymptom === 'Não' && (
          <GroupIdentification setValue={setValue} watch={watch} />
        ))}
      {earGroup && (
        <ChronicDisease
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          onSubmit={onSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  )
}

export default EarProtocol
