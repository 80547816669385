import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const NauseaOrVomit = ({ setValue, watch }) => {
  const severity = watch('severity') || 0

  const handle = (nausea, weight) => {
    if (weight > severity) {
      setValue('severity', weight)
    }
    setValue('nausea', nausea)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }
  
  const handleVomit = (vomit, weight) => {
    if (weight > severity) {
      setValue('severity', weight)
    }
    setValue('vomit', vomit)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Você apresenta náusea ou vômito?</h2>
          <div>
            <Button type='button' onClick={() => handle('Náusea', 3)}>
              Náusea
            </Button>
            <Button type='button' onClick={() => handleVomit('Vômito', 4)}>
              Vômito
            </Button>
            <Button type='button' onClick={() => handle('Nenhuma', 2)}>
              Nenhuma
            </Button>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}
export default NauseaOrVomit
