import { useState } from 'react'
import ButtonConfirm from '../../components/ButtonConfirm'
import { Checkbox } from '../../components/Checkbox'
import { AnimationContainer, Container, Content } from './styles'

const KindOfBellyPain = ({ setValue }) => {
  const [bellyPain, setBellyPain] = useState([])

  const handle = (bellyPain) => {
    setValue('kindOfBellyPain', bellyPain)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  const checkBoxState = (e, v) => {
    if (e.target?.checked) {
      setBellyPain((state) => [...state, v])
    } else {
      setBellyPain((state) => state.filter((e) => e !== v))
    }
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Qual é o tipo da dor de barriga?</h2>
          <div>
            <Checkbox
              type='button'
              id='bellyPain-1'
              label='Cólica'
              onChange={(e) => checkBoxState(e, 'Cólica')}
            />
            <Checkbox
              type='button'
              id='bellyPain-2'
              label='Facada/pontada'
              onChange={(e) => checkBoxState(e, 'Facada/pontada')}
            />
            <Checkbox
              type='button'
              id='bellyPain-3'
              label='Queimação'
              onChange={(e) => checkBoxState(e, 'Queimação')}
            />
            <Checkbox
              type='button'
              id='bellyPain-4'
              label='Pressão'
              onChange={(e) => checkBoxState(e, 'Pressão')}
            />

            <ButtonConfirm
              type='button'
              onClick={() => {
                if (bellyPain.length > 0) handle(bellyPain)
              }}
            >
              Confirmar
            </ButtonConfirm>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}
export default KindOfBellyPain
