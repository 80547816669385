import { forwardRef } from "react";
import { Input, Label } from "./styles";
// import { CheckmarkIcon } from "../CheckmarkIcon";
// import * as S from "./styles";

export const Checkbox = forwardRef(
  (
    {
      label,
      checked,
      onChange,
      id: customId,
      highlighted,
      disabled,
      ...props
    },
    ref
  ) => {
    const id = customId;

    return (
      <div
        data-highlighted={highlighted}
        {...props}
        ref={ref}
      >
        <Input
          type="checkbox"
          id={id}
          value={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <Label htmlFor={id}>{label && label}</Label>
      </div>
    );
  }
);
