import styled, { keyframes } from 'styled-components'
import { Checkbox } from '../../components/Checkbox'
import Button from '../../components/Button'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  & > ${Checkbox} {
    justify-content: center;
    width: 100%;
    @media (max-width: 767px) {
      height: auto;
    }
  }
`

export const Background = styled.div`
  @media (min-width: 1100px) {
    flex: 1;
    background: no-repeat center, var(--black);
    background-size: contain;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  & > ${Checkbox} {
    width: 60%;
    @media (max-width: 767px) {
      width: 80%;
    }
  }
`

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px)        
    }
    to {
        opacity: 1;
        transform: translateX(0px)
    }
`

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${appearFromLeft} 1s;

  div {
    display: flex;
  }

  h2 {
    margin: 0 0 29px;
  }

  & > ${Checkbox} {
    #submit {
      background: none;
      border: none;
      box-shadow: none;
    }
    h2 {
      margin: 0 0 29px;
    }
    div {
      display: grid;
      flex-wrap: wrap;
      flex-direction: column;
      max-height: 130vh;
      gap: 1rem;
      grid-template-columns: 1fr 1fr;
      max-width: 100%;
      div {
        max-width: 100%;
        box-shadow: rgb(0 0 0 / 10%) 1px 1px 8px;
        border-radius: 5px;
        background: rgb(255, 255, 255);
        -webkit-box-align: center;
        align-items: center;
        padding: 0px 1rem;
        margin-bottom: 12px;
        margin-right: 16px;
        min-height: 6rem;
        line-height: 1.25rem;
        border: 1px solid transparent;
        display: flex;
        flex-direction: row;
        flex-flow: row nowrap;
      }
      @media (max-width: 767px) {
        max-height: 100%;
        grid-template-columns: 1fr;

        div {
          width: 100%;
        }
        span {
          font-size: 0.75rem;
        }
        button {
          width: 100%;
        }
      }
    }
  }
  & > ${Button} {
    align-items: center;

    form {
      margin: 80px 0;
      width: 340px;
      text-align: center;

      h1 {
        margin-bottom: 32px;
      }

      > div {
        margin-top: 16px;
      }

      p {
        margin-top: 8px;

        a {
          font-weight: bold;
          color: var(--orange);
        }
      }
    }
  }
`
