import { useState } from 'react'
import Button from "../../components/Button";
import ButtonConfirm from '../../components/ButtonConfirm'
import { Checkbox } from '../../components/Checkbox'
import AnyOtherSymptom from '../../SharedQuestions/AnyOtherSymptom'
import ChronicDisease from '../../SharedQuestions/ChronicDisease'
import ExtraSymptoms from '../../SharedQuestions/ExtraSymptoms'
import Fever from '../../SharedQuestions/Fever'
import HeadacheLocation from '../../SharedQuestions/HeadacheLocation';
import HowPainStarted from '../../SharedQuestions/HowPainStarted'
import PainCharacteristic from '../../SharedQuestions/PainCharacteristic'
import PainIntensity from '../../SharedQuestions/PainIntensity'
import Smoker from '../../SharedQuestions/Smoker'
import WhenSymptomsStarted from '../../SharedQuestions/WhenSymptomsStarted'
import { AnimationContainer, Container, Content } from './styles'


const HeadacheProtocol = ({
  setValue,
  onSubmit,
  getValues,
  watch,
  isLoading,
  setIsLoading,
}) => {

  const pain = watch('painLocation')
  const when = watch('whenSymptomsStarted') || false
  const painChar = watch('painCharacteristic')
  const kindOfPain = watch('kindOfPain')
  const howPainStarted = watch('howPainStarted')
  const painIntensity = watch('painIntensity')
  const fever = watch('fever')
  const anotherSymptom = watch('anotherSymptom')
  const extraSymptomsAwnser = watch('extraSymptomsAwnser')
  const smoker = watch('smoker')
  const headHitAwnsered = watch('hit')
  const movementAwnser = watch('movement')
  const stressAwnser = watch('stress')
  const strengthAwnser = watch('strength')
  const alterationAwnser = watch('alteration')
  const severity = watch('severity') || 0

  const handle = (key, value, weight) => {
    if (weight > severity) {
      setValue('severity', weight)
    }
    setValue(key, value)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  return (
    <>
      <HeadacheLocation setValue={setValue}/>
      {pain && <WhenSymptomsStarted setValue={setValue} />}
      {when && <PainCharacteristic setValue={setValue} />}
      {painChar && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Como é a dor?</h2>
              <div>
                <Button
                  type='button'
                  onClick={() => {
                    handle('kindOfPain', 'Pulsátil', 0)
                  }}
                >
                  Tipo pulsátil
                </Button>
                <Button
                  type='button'
                  onClick={() => {
                    handle('kindOfPain', 'Pressão', 0)
                  }}
                >
                  Tipo pressão
                </Button>
                <Button
                  type='button'
                  onClick={() => {
                    handle('kindOfPain', 'Queimação', 0)
                  }}
                >
                  Em queimação
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {kindOfPain && <HowPainStarted setValue={setValue} watch={watch}/>}
      {howPainStarted && <PainIntensity setValue={setValue} watch={watch} />}
      {painIntensity && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você bateu a cabeça recentemente?</h2>
              <div>
                <Button type='button' onClick={() => handle('hit', 'Sim', 4)}>
                  Sim
                </Button>
                <Button type='button' onClick={() => handle('hit', 'Não', 2)}>
                  Não
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {headHitAwnsered && <Fever setValue={setValue} watch={watch} />}
      {!!fever && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você apresenta dor ao movimentar a nuca?</h2>
              <div>
                <Button type='button' onClick={() => handle('movement', 'Sim', 4)}>
                  Sim
                </Button>
                <Button type='button' onClick={() => handle('movement', 'Não', 2)}>
                  Não
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {movementAwnser && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você está mais estressado(a) do que o habitual?</h2>
              <div>
                <Button type='button' onClick={() => handle('stress', 'Sim', 2)}>
                  Sim
                </Button>
                <Button type='button' onClick={() => handle('stress', 'Não', 2)}>
                  Não
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {stressAwnser && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>
                Você apresenta alteração de força motora ou sensibilidade em alguma parte do corpo de início recente ou junto com a dor de cabeça?
              </h2>
              <div>
                <Button type='button' onClick={() => handle('strength', 'Sim', 4)}>
                  Sim
                </Button>
                <Button type='button' onClick={() => handle('strength', 'Não', 2)}>
                  Não
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {strengthAwnser === 'Sim' && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Que tipo de alteração?</h2>
              <div>
                <Button
                  type='button'
                  onClick={() => handle('alteration', 'Formigamento ou dor em um lado do corpo', 0)}
                >
                  Formigamento ou dor em um lado do corpo
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('alteration', 'Diminuição de força em um lado do corpo', 0)}
                >
                  Diminuição de força em um lado do corpo
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('alteration', 'Formigamento ou dor em uma perna', 0)}
                >
                  Formigamento ou dor em uma perna
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('alteration', 'Diminuição de força em um braço', 0)}
                >
                  Diminuição de força em um braço
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('alteration', 'Não consigo caminhar direito', 0)}
                >
                  Não consigo caminhar direito
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('alteration', '"Mão boba", não consigo pegar coisas com uma mão', 0)}
                >
                  "Mão boba", não consigo pegar coisas com uma mão
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {(alterationAwnser && <AnyOtherSymptom setValue={setValue} watch={watch} />) ||
        (strengthAwnser === 'Não' && <AnyOtherSymptom setValue={setValue} watch={watch} />)}
      {anotherSymptom === 'Sim' && <ExtraSymptoms setValue={setValue} />}
      {(anotherSymptom === 'Não' && <Smoker setValue={setValue} />) ||
        (extraSymptomsAwnser && <Smoker setValue={setValue} />)}
      {smoker && (
        <ChronicDisease
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          onSubmit={onSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  )
}

export default HeadacheProtocol
