import { useState } from 'react'
import ButtonConfirm from '../../components/ButtonConfirm'
import { Checkbox } from '../../components/Checkbox'
import { AnimationContainer, Container, Content } from './styles'

const HeadacheLocation = ({setValue}) => {
    const [painLocation, setPainLocation] = useState([])

    const handleButton = (painLocation) => {
        setValue('painLocation', painLocation)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
        }, 200)
    }

    const checkBoxState = (e, v) => {
        if (e.target?.checked) {
            setPainLocation((state) => [...state, v])
        } else {
            setPainLocation((state) => state.filter((e) => e !== v))
        }
    }

    return (
        <Container>
        <Content>
          <AnimationContainer>
            <h2>Qual é o local da dor?</h2>
            <div>
              <Checkbox
                id='painLocation-1'
                label='Na frente (testa)'
                onChange={(e) => checkBoxState(e, 'Na frente (testa)')}
              />
              <Checkbox
                id='painLocation-2'
                label='Atrás'
                onChange={(e) => checkBoxState(e, 'Atrás')}
              />
              <Checkbox
                id='painLocation-3'
                label='Na nuca'
                onChange={(e) => checkBoxState(e, 'Na nuca')}
              />
              <Checkbox
                id='painLocation-4'
                label='De um lado'
                onChange={(e) => checkBoxState(e, 'De um lado')}
              />
              <Checkbox
                id='painLocation-5'
                label='Toda a cabeça'
                onChange={(e) => checkBoxState(e, 'Toda a cabeça')}
              />
            </div>
              <ButtonConfirm
                type='button'
                onClick={() => {
                  if (painLocation.length > 0) handleButton(painLocation)
                }}
              >
                Confirmar
              </ButtonConfirm>
          </AnimationContainer>
        </Content>
      </Container>
    )
   
}

export default HeadacheLocation
