import AssociatedSymptom from "../../MainTriage/AssociatedSymptom";
import HowSymptomsStarted from "../../MainTriage/HowSymptomsStarted";
import IsItBetter from "../../MainTriage/IsItBetter";
import ChronicDisease from "../../SharedQuestions/ChronicDisease";
import Impact from "../../SharedQuestions/Impact";
import Medicine from "../../SharedQuestions/Medicine";
import Pain from "../../SharedQuestions/Pain";
import WhenSymptomsStarted from "../../SharedQuestions/WhenSymptomsStarted";

const GenericProtocol = ({ setValue, watch, getValues, isLoading, setIsLoading, onSubmit }) => {
    const associatedSymptom = watch("associatedSymptom") || false;
    const howSymptomsStarted = watch("howSymptomsStarted") || false;
    const whenSymptomsStarted = watch("whenSymptomsStarted") || false;
    const isItBetter = watch("isItBetter") || false;
    const painUnconfort = watch("painUnconfort") || false;
    const impact = watch("impact") || false;
    const medicine = watch("medicine") || false;

    return (
        <>
            <AssociatedSymptom setValue={setValue} getValues={getValues} watch={watch} />
            {associatedSymptom && associatedSymptom !== "Nenhum acima" && <HowSymptomsStarted setValue={setValue} watch={watch} />}
            {howSymptomsStarted && <WhenSymptomsStarted setValue={setValue} />}
            {whenSymptomsStarted && <IsItBetter setValue={setValue} watch={watch} />}
            {isItBetter && <Pain setValue={setValue} watch={watch} />}
            {painUnconfort && <Impact setValue={setValue} watch={watch} />}
            {impact && <Medicine setValue={setValue} watch={watch} />}
            {
                (medicine && <ChronicDisease watch={watch} setValue={setValue} getValues={getValues} isLoading={isLoading} setIsLoading={setIsLoading} onSubmit={onSubmit}  />)
                ||
                (associatedSymptom === "Nenhum acima" && <ChronicDisease watch={watch} setValue={setValue} getValues={getValues} onSubmit={onSubmit} isLoading={isLoading} setIsLoading={setIsLoading} />)
            }
        </>
    )
};

export default GenericProtocol;  