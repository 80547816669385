import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  header {
    img {
      height: 120px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  header {
    height: 103px;
    width: 60%;
  }
  #confirm {
    width: 60%;
  }
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px)        
    }
    to {
        opacity: 1;
        transform: translateX(0px)
    }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${appearFromLeft} 1s;
  background-color: var(--orange);
  width: 100vw;
  padding: 28px 0;
  h2 {
    color: #fff;
    font-size: 48px;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    text-align: left;
    margin-bottom: 2rem;
  }
  h4 {
    color: var(--white);
    opacity: 0.7;
    margin-bottom: 23px;
    width: 60%;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    line-height: 1.187rem;
    font-weight: 400;
    white-space: pre-wrap;
  }
  label {
    color: var(--white);
    font-size: 1.2rem;
  }
  label:hover {
    cursor: pointer;
  }
  div {
    max-width: 60%;
  }
  div > div {
    display: inline;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 1.25rem;
    }
    h4 {
      font-size: 1rem;
      width: 100%;
    }
    label {
      font-size: 1rem;
    }
  }
`;
