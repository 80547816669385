import Button from "../../components/Button";
import { AnimationContainer, Container, Content } from "./styles";


const TestCovid = ({ setValue }) => {
    // aqui tem red severity em caso de sim
    const handle = (bool) => {
        setValue("testCovid", bool)
        if (bool === "Sim") {
            setValue('severity', 4)
        }
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Você testou positivo para COVID-19?
                    </h2>
                    <div>
                        <Button type="Button" onClick={() => handle("Sim")}>Sim</Button>
                        <Button type="Button" onClick={() => handle("Não")}>Não</Button>
                    </div>
                </AnimationContainer>
            </Content>
        </Container>
    )
};


export default TestCovid;