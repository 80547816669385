import Button from "../../components/Button";
import { AnimationContainer, Container, Content } from "./styles";

const Medicine = ({ setValue, watch }) => {
    const severity = watch('severity') || 0
    const handle = (medicine, weight) => {
        if (weight > severity) {
            setValue('severity', weight)
        }
        setValue("medicine", medicine)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }
    // blue
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Você se sente melhor após tomar algum medicamento?
                    </h2>
                    {/* blue */}
                    <div>
                        <Button type="button" onClick={() => handle("Não tomei remédios para esses sintomas", 2)}>Não tomei remédios para esses sintomas</Button>
                        <Button type="button" onClick={() => handle("Sim, tive melhora com remédio", 2)}>Sim, tive melhora com remédio</Button>
                        {/* yellow */}
                        <Button type="button" onClick={() => handle("Não, não teve diferença tomar remédio", 3)}>Não, não teve diferença tomar remédio</Button>
                    </div>
                </AnimationContainer>
            </Content>

        </Container>
    )
};

export default Medicine;