import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const SymptomsList = ({ setValue }) => {
  const handle = (disease) => {
    setValue('disease', disease)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }
  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>
            OK! Qual é o seu PRINCIPAL sintoma? Aqui vai uma lista dos mais
            comuns
          </h2>
          <div>
            <Button type='button' onClick={() => handle('headache')}>
              Dor de cabeça
            </Button>
            <Button type='button' onClick={() => handle('earPain')}>
              Dor de ouvido
            </Button>
            <Button type='button' onClick={() => handle('throatPain')}>
              Dor de garganta
            </Button>
            <Button type='button' onClick={() => handle('superiorBellyPain')}>
              Dor abdominal (na barriga) na parte superior
            </Button>
            <Button type='button' onClick={() => handle('inferiorBellyPain')}>
              Dor abdominal (na barriga) na parte inferior
            </Button>
            <Button type='button' onClick={() => handle('urinePain')}>
              Dor para urinar
            </Button>
            <Button type='button' onClick={() => handle('diarrhea')}>
              Diarréia
            </Button>
            <Button type='button' onClick={() => handle('depressionAnxiety')}>
              Depressão, Ansiedade e Estresse
            </Button>

            <Button type='button' onClick={() => handle('others')}>
              Outros
            </Button>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}

export default SymptomsList
