import { useState } from 'react'
import ButtonConfirm from '../../components/ButtonConfirm'
import { Checkbox } from '../../components/Checkbox'
import { AnimationContainer, Container, Content } from './styles'

const Worsening = ({ setValue }) => {
  const [bellyPainWorsening, setBellyPainWorsening] = useState([])
  const handle = (bellyPainWorsening) => {
    setValue('bellyPainWorsening', bellyPainWorsening)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  const checkBoxState = (e, v) => {
    if (e.target?.checked) {
      setBellyPainWorsening((state) => [...state, v])
    } else {
      setBellyPainWorsening((state) => state.filter((e) => e !== v))
    }
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>A dor piora em alguma dessas situações?</h2>
          <div>
            <Checkbox
              type='button'
              id='bellyPainWorsening-1'
              label='A dor piora após comer'
              onChange={(e) => checkBoxState(e, 'A dor piora após comer')}
            />
            <Checkbox
              type='button'
              id='bellyPainWorsening-2'
              label="A dor piora em jejum, com a 'barriga vazia'"
              onChange={(e) =>
                checkBoxState(e, "A dor piora em jejum, com a barriga vazia")
              }
            />
            <Checkbox
              type='button'
              id='bellyPainWorsening-3'
              label='A dor piora ao respirar'
              onChange={(e) => checkBoxState(e, 'A dor piora ao respirar')}
            />
            <Checkbox
              type='button'
              id='bellyPainWorsening-4'
              label='A dor piora ao deitar'
              onChange={(e) => checkBoxState(e, 'A dor piora ao deitar')}
            />
            <Checkbox
              type='button'
              id='bellyPainWorsening-5'
              label='A dor piora ao se movimentar'
              onChange={(e) => checkBoxState(e, 'A dor piora ao se movimentar')}
            />
            <Checkbox
              type='button'
              id='bellyPainWorsening-6'
              label='Não tem relação com nenhuma dessas situações'
              onChange={(e) =>
                checkBoxState(e, 'Não tem relação com nenhuma dessas situações')
              }
            />
            <ButtonConfirm
              type='button'
              onClick={() => {
                if (bellyPainWorsening.length > 0) handle(bellyPainWorsening)
              }}
            >
              Confirmar
            </ButtonConfirm>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}
export default Worsening
