import { useState } from "react";
import ButtonConfirm from "../../components/ButtonConfirm";
import { Checkbox } from "../../components/Checkbox";
import { AnimationContainer, Container, Content } from "./styles";

const FluSymptoms = ({ setValue, isCovidProtocol, watch }) => {
    const severity = watch('severity') || 0;
    const [fluSymptoms, setFluSymptoms] = useState([])
    const handle = (flu) => {
        if (fluSymptoms.includes("Febre ou calafrio por mais de 24 horas, sem melhoras") 
        || fluSymptoms.includes("Respiração mais rápida que o normal")
        || fluSymptoms.includes("Cansaço ao esforço (cansa ao subir 2 degraus ou caminhar 4 passos)")
        || fluSymptoms.includes("Sonolência")
        || fluSymptoms.includes("Vômito mais de uma vez")
        || fluSymptoms.includes("Dor na região do tórax ou das costas")
        || fluSymptoms.includes("Muita sede ou urinando menos que o habitual")
        || fluSymptoms.includes("Você está no décimo dia de início de sintoma e teve febre (temperatura axilar a partir de 37,8o) nas últimas 24 horas")
        ) {
            if (severity < 3) {
                setValue('severity', 3)
            }
        }
        else {
            if (severity < 2) {
                setValue('severity', 2)
            }
        }
        setValue('fluSymptoms', flu)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Você apresenta algum dos sintomas indicados abaixo?
                    </h2>
                    <div>
                        <Checkbox type="button" id="fluSymptoms-1" label="Febre ou calafrio por menos de 24 horas" onChange={() => setFluSymptoms(state => [...state, "Febre ou calafrio por menos de 24 horas"])} />
                        <Checkbox type="button" id="fluSymptoms-2" label="Coriza ou congestão nasal" onChange={() => setFluSymptoms(state => [...state, "Coriza ou congestão nasal"])} />
                        <Checkbox type="button" id="fluSymptoms-3" label="Dor de garganta" onChange={() => setFluSymptoms(state => [...state, "Dor de garganta"])} />
                        <Checkbox type="button" id="fluSymptoms-4" label="Tosse" onChange={() => setFluSymptoms(state => [...state, "Tosse"])} />
                        <Checkbox type="button" id="fluSymptoms-5" label="Perda de olfato ou paladar" onChange={() => setFluSymptoms(state => [...state, "Perda de olfato ou paladar"])} />
                        <Checkbox type="button" id="fluSymptoms-6" label="Febre ou calafrio por mais de 24 horas, sem melhoras" onChange={() => setFluSymptoms(state => [...state, "Febre ou calafrio por mais de 24 horas, sem melhoras"])} />
                        <Checkbox type="button" id="fluSymptoms-7" label="Respiração mais rápida que o normal" onChange={() => setFluSymptoms(state => [...state, "Respiração mais rápida que o normal"])} />
                        <Checkbox type="button" id="fluSymptoms-8" label="Muita sede ou urinando menos que o habitual" onChange={() => setFluSymptoms(state => [...state, "Muita sede ou urinando menos que o habitual"])} />
                        <Checkbox type="button" id="fluSymptoms-9" label="Cansaço ao esforço (cansa ao subir 2 degraus ou caminhar 4 passos" onChange={() => setFluSymptoms(state => [...state, "Cansaço ao esforço"])} />
                        <Checkbox type="button" id="fluSymptoms-10" label="Sonolência" onChange={() => setFluSymptoms(state => [...state, "Sonolência"])} />
                        <Checkbox type="button" id="fluSymptoms-11" label="Vômito mais de uma vez" onChange={() => setFluSymptoms(state => [...state, "Vômito mais de uma vez"])} />
                        <Checkbox type="button" id="fluSymptoms-12" label="Dor na região do tórax ou das costas" onChange={() => setFluSymptoms(state => [...state, "Dor na região do tórax ou das costas"])} />
                        <Checkbox type="button" id="fluSymptoms-13" label="Muita sede ou urinando mais que o habitual" onChange={() => setFluSymptoms(state => [...state, "Muita sede ou urinando mais que o habitual"])} />
                        <Checkbox type="button" id="fluSymptoms-14" label="Você está no décimo dia de início de sintoma e teve febre (temperatura axiliar acima de 37,8 graus) nas últimas 24 horas"
                            onChange={() => setFluSymptoms(state => [...state, "Você está no décimo dia de início de sintoma e teve febre (temperatura axiliar acima de 37,8 graus) nas últimas 24 horas"])}
                        />
                        <ButtonConfirm type="button" onClick={() => handle(["Nenhum acima"])}>Nenhuma acima</ButtonConfirm>
                    </div>
                    <ButtonConfirm type="button" onClick={() => handle(fluSymptoms)}>Confirmar</ButtonConfirm>
                </AnimationContainer>
            </Content>
        </Container>
    )
}
export default FluSymptoms;