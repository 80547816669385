import styled, { css } from "styled-components";

export const Container = styled.div`
  text-align: left;
  width: 60%;
  div {
    span {
      color: var(--red);
    }
    color: rgb(153, 153, 153);
    font-size: 1.125rem;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    display: block;
  }
`;
