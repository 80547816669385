import { useState } from 'react'
import Button from '../../components/Button'
import { Checkbox } from '../../components/Checkbox'
import AnyOtherSymptom from '../../SharedQuestions/AnyOtherSymptom'
import ChronicDisease from '../../SharedQuestions/ChronicDisease'
import ExtraSymptoms from '../../SharedQuestions/ExtraSymptoms'
import Fever from '../../SharedQuestions/Fever'
import HowIsUrine from '../../SharedQuestions/HowIsUrine'
import HowPainStarted from '../../SharedQuestions/HowPainStarted'
import Smoker from '../../SharedQuestions/Smoker'
import WhenSymptomsStarted from '../../SharedQuestions/WhenSymptomsStarted'
import { AnimationContainer, Container, Content } from './styles'
import ButtonConfirm from '../../components/ButtonConfirm'

const UrineProtocol = ({
  setValue,
  onSubmit,
  getValues,
  watch,
  isLoading,
  setIsLoading,
}) => {
  const [urineFlux, setUrineFlux] = useState([])
  const [urineAlteration, setUrineAlteration] = useState([])
  const [complaints, setComplaints] = useState([])
  const [kidneyDisease, setKidneyDisease] = useState([])

  const when = watch('whenSymptomsStarted') || false
  const howIsUrineFlux = watch('urineFlux') || false
  const urineAlt = watch('urineAlt') || false
  const extraComplaints = watch('complaints')
  const sexualRelation = watch('sexualRelation')
  const lastSixMonths = watch('lastSixMonths')
  const unprotectedSex = watch('unprotectedSex')
  const backPain = watch('backPain')
  const testiclePain = watch('testiclePain')
  const anotherSymptom = watch('anotherSymptom')
  const extraSymptomsAwnser = watch('extraSymptomsAwnser')
  const kidneyAwnser = watch('kidneyDisease')
  const howPainStarted = watch('howPainStarted')
  const smoker = watch('smoker')
  const howIsUrine = watch('howIsUrine')
  const fever = watch('fever')
  const severity = watch('severity') || 0

  const handle = (key, value, weight) => {
    if (weight > severity) {
      setValue('severity', weight)
    }

    setValue(key, value)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  const checkBoxUrineFlux = (e, v) => {
    if (e.target?.checked) {
      setUrineFlux((state) => [...state, v])
    } else {
      setUrineFlux((state) => state.filter((e) => e !== v))
    }
  }

  const checkBoxUrineAlt = (e, v) => {
    if (e.target?.checked) {
      setUrineAlteration((state) => [...state, v])
    } else {
      setUrineAlteration((state) => state.filter((e) => e !== v))
    }
  }

  const checkBoxComplaints = (e, v) => {
    if (e.target?.checked) {
      setComplaints((state) => [...state, v])
    } else {
      setComplaints((state) => state.filter((e) => e !== v))
    }
  }

  const checkBoxKidney = (e, v) => {
    if (e.target?.checked) {
      setKidneyDisease((state) => [...state, v])
    } else {
      setKidneyDisease((state) => state.filter((e) => e !== v))
    }
  }

  return (
    <>
      <WhenSymptomsStarted setValue={setValue} />
      {when && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você notou alguma destas mudança no fluxo urinário?</h2>
              <div>
                <Checkbox
                  id='urineFlux-1'
                  label='Diminuiu muito a quantidade de urina nas últimas 24 horas'
                  onChange={(e) =>
                    checkBoxUrineFlux(
                      e,
                      'Diminuiu muito a quantidade de urina nas últimas 24 horas'
                    )
                  }
                />
                <Checkbox
                  id='urineFlux-2'
                  label='Não fiz xixi nas últimas 24 horas'
                  onChange={(e) =>
                    checkBoxUrineFlux(e, 'Não fiz xixi nas últimas 24 horas')
                  }
                />
                <Checkbox
                  id='urineFlux-3'
                  label='O fluxo urinário (jato) está muito mais fraco'
                  onChange={(e) =>
                    checkBoxUrineFlux(
                      e,
                      'O fluxo urinário (jato) está muito mais fraco'
                    )
                  }
                />
                <Checkbox
                  id='urineFlux-4'
                  label='Está vazando xixi'
                  onChange={(e) => checkBoxUrineFlux(e, 'Está vazando xixi')}
                />
                <Checkbox
                  id='urineFlux-5'
                  label='A quantidade de urina aumentou'
                  onChange={(e) =>
                    checkBoxUrineFlux(e, 'A quantidade de urina aumentou')
                  }
                />
              </div>
            <ButtonConfirm
                type='button'
                onClick={() => {
                if (urineFlux.length > 0) handle('urineFlux', urineFlux, 0)
                }}
            >
                Confirmar
            </ButtonConfirm>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {howIsUrineFlux && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você percebeu as alterações a seguir?</h2>
              <div>
                <Checkbox
                  id='urineAlteration-1'
                  label='Acordar à noite várias vezes para urinar'
                  onChange={(e) =>
                    checkBoxUrineAlt(
                      e,
                      'Acordar à noite várias vezes para urinar'
                    )
                  }
                />
                <Checkbox
                  id='urineAlteration-2'
                  label='Urgência para urinar'
                  onChange={(e) => checkBoxUrineAlt(e, 'Urgência para urinar')}
                />
                <Checkbox
                  id='urineAlteration-3'
                  label='Dificuldade em começar a urinar'
                  onChange={(e) =>
                    checkBoxUrineAlt(e, 'Dificuldade em começar a urinar')
                  }
                />
              </div>
            <ButtonConfirm
                type='button'
                onClick={() => {
                if (urineAlteration.length > 0)
                    handle('urineAlt', urineAlteration)
                }}
            >
                Confirmar
            </ButtonConfirm>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {urineAlt && <HowIsUrine setValue={setValue} watch={watch} />}
      {howIsUrine && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você apresenta alguma destas queixas?</h2>
              <div>
                <Checkbox
                  id='complaints-1'
                  label='Dor na genitália (pênis ou vulva)'
                  onChange={(e) =>
                    checkBoxComplaints(e, 'Dor na genitália (pênis ou vulva)')
                  }
                />
                <Checkbox
                  id='complaints-2'
                  label='Alteração na pele ao redor da genitália'
                  onChange={(e) =>
                    checkBoxComplaints(e, 'Dor na genitália (pênis ou vulva)')(
                      e,
                      'Alteração na pele ao redor da genitália'
                    )
                  }
                />
                <Checkbox
                  id='complaints-3'
                  label='Secreção uretral (saindo pela uretra)'
                  onChange={(e) =>
                    checkBoxComplaints(e, 'Dor na genitália (pênis ou vulva)')(
                      e,
                      'Secreção uretral (saindo pela uretra)'
                    )
                  }
                />
                <Checkbox
                  id='complaints-4'
                  label='Nenhuma acima'
                  onChange={(e) =>
                    checkBoxComplaints(e, 'Dor na genitália (pênis ou vulva)')(
                      e,
                      'Nenhuma acima'
                    )
                  }
                />
              </div>
            <ButtonConfirm
                type='button'
                onClick={() => {
                if (complaints.length > 0) handle('complaints', complaints, 3)
                }}
            >
                Confirmar
            </ButtonConfirm>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {extraComplaints && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você já teve relação sexual (vaginal ou anal)?</h2>
              <div>
              <Button
                type='button'
                onClick={() => handle('sexualRelation', 'Sim', 0)}
                >
                Sim, já tive relação sexual
              </Button>
              <Button
                type='button'
                onClick={() => handle('sexualRelation', 'Não', 0)}
                >
                Não, nunca tive relação sexual
              </Button>
                </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {sexualRelation && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você teve relação sexual nos últimos 6 meses?</h2>
              <div>
                <Button
                    type='button'
                    onClick={() => handle('lastSixMonths', 'Sim', 0)}
                    >
                    Sim
                </Button>
                <Button
                    type='button'
                    onClick={() => handle('lastSixMonths', 'Não', 0)}
                    >
                    Não
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {lastSixMonths && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>
                Você teve relação sexual desprotegida nos últimos 6 meses?
              </h2>
              <div>
                <Button
                    type='button'
                    onClick={() => handle('unprotectedSex', 'Sim', 0)}
                    >
                    Sim
                </Button>
                <Button
                    type='button'
                    onClick={() => handle('unprotectedSex', 'Não', 0)}
                    >
                    Não
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {unprotectedSex && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você tem dor nas costas?</h2>
              <div>

              <Button
                type='button'
                onClick={() => handle('backPain', 'Dor Superior', 4)}
                >
                Sim, na parte mais superior das costas
              </Button>
              <Button
                type='button'
                onClick={() => handle('backPain', 'Dor Inferior', 4)}
                >
                Sim, na parte mais baixa das costas
              </Button>
              <Button type='button' onClick={() => handle('backPain', 'Não', 2)}>
                Não
              </Button>
            </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {backPain === 'Não' && (
          <Container>
          <Content>
            <AnimationContainer>
              <h2>Você apresenta dor nos testículos?</h2>
              <div>
                <Button
                    type='button'
                    onClick={() => handle('testiclePain', 'A dor irradia', 3)}
                    >
                    Sim, a dor irradia para os testículos
                </Button>
                <Button
                    type='button'
                    onClick={() => handle('testiclePain', 'Não irradia', 2)}
                    >
                    Não, a dor não irradia para os testículos
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {backPain && backPain !== 'Não' && <HowPainStarted setValue={setValue} watch={watch} />}
      {howPainStarted && <Fever setValue={setValue} watch={watch} />}
      {fever && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você apresenta dor nos testículos?</h2>
              <div>
              <Button
                type='button'
                onClick={() => handle('testiclePain', 'A dor irradia', 3)}
              >
                Sim, a dor irradia para os testículos
              </Button>
              <Button
                type='button'
                onClick={() => handle('testiclePain', 'Não irradia', 2)}
              >
                Não, a dor não irradia para os testículos
              </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {testiclePain && <AnyOtherSymptom setValue={setValue} watch={watch} />}
      {(anotherSymptom === 'Sim' && <ExtraSymptoms setValue={setValue} />) ||
        (anotherSymptom === 'Não' && <Smoker setValue={setValue} />)}
      {extraSymptomsAwnser && <Smoker setValue={setValue} />}
      {smoker && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Possui alguma das doenças indicadas abaixo?</h2>
              <div>
                <Checkbox
                  id='kidneyDisease-1'
                  label='Infecção urinária de repetição'
                  onChange={(e) =>
                    checkBoxKidney(e, 'Infecção urinária de repetição')
                  }
                />

                <Checkbox
                  id='kidneyDisease-2'
                  label='Pedra nos rins'
                  onChange={(e) => checkBoxKidney(e, 'Pedra nos rins')}
                />
                <Checkbox
                  id='kidneyDisease-3'
                  label='Nenhuma acima'
                  onChange={(e) => checkBoxKidney(e, 'Nenhuma acima')}
                />
              </div>
            <ButtonConfirm
                type='button'
                onClick={() => {
                if (kidneyDisease.length > 0)
                    handle('kidneyDisease', kidneyDisease)
                }}
            >
                Confirmar
            </ButtonConfirm>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {kidneyAwnser && (
        <ChronicDisease
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          onSubmit={onSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  )
}

export default UrineProtocol
