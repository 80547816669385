import { useState } from 'react'
import ButtonConfirm from '../../components/ButtonConfirm'
import { Checkbox } from '../../components/Checkbox'
import { AnimationContainer, Container, Content } from './styles'

const HowIsUrine = ({ setValue, watch }) => {
  const [howIsUrine, setHowIsUrine] = useState([])
  const severity = watch('severity') || 0

  const handle = (howIsUrine, weight) => {
    if (weight > severity) {
      setValue('severity', weight)
    }
    setValue('howIsUrine', howIsUrine)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  const checkBoxState = (e, v) => {
    if (e.target?.checked) {
      setHowIsUrine((state) => [...state, v])
    } else {
      setHowIsUrine((state) => state.filter((e) => e !== v))
    }
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>E como está a urina?</h2>
          <div>
            <Checkbox
              type='button'
              id='howIsUrine-1'
              label='Escura e concentrada'
              onChange={(e) => checkBoxState(e, 'Escura e concentrada')}
            />
            <Checkbox
              type='button'
              id='howIsUrine-2'
              label='Está vermelha e/ou parece ter sangue'
              onChange={(e) =>
                checkBoxState(e, 'Está vermelha e/ou parece ter sangue')
              }
            />
            <Checkbox
              type='button'
              id='howIsUrine-3'
              label='Está fazendo mais espuma do que o normal'
              onChange={(e) =>
                checkBoxState(e, 'Está fazendo mais espuma do que o normal')
              }
            />
            </div>
            <ButtonConfirm
              type='button'
              onClick={() => {
                if (howIsUrine.length > 0) handle(howIsUrine, 3)
              }}
            >
              Confirmar
            </ButtonConfirm>
        </AnimationContainer>
      </Content>
    </Container>
  )
}
export default HowIsUrine
