import Button from "../../components/Button";
import { AnimationContainer, Container, Content } from "./styles";

const Triage = ({ setValue, severity, setSeverity }) => {
    const newTriage = (triage) => {
        setValue("triage", triage)
        if (!triage) {
            setSeverity('waiting')
            setValue('severity', severity)
        }
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    };

    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Identificamos que você já respondeu a nossa triagem virtual.<br />
                        Deseja iniciar um novo atendimento ou visualizar seu anterior?
                    </h2>
                    <div>
                        <Button type="button" onClick={() => {newTriage(true)}}>Novo atendimento</Button>
                        <Button type="button" onClick={() => {newTriage(false)}}>Aguardar</Button>
                    </div>
                </AnimationContainer>
            </Content>

        </Container>
    )
};


export default Triage;