import { useState } from "react";
import ButtonConfirm from "../../components/ButtonConfirm";
import { Checkbox } from "../../components/Checkbox";
import { AnimationContainer, Container, Content } from "./styles";

const LackOfAir = ({ setValue, watch }) => {
    const severity = watch('severity') || 0
    const [lackOfAir, setLackOfAir] = useState([])
    
    const handle = (symps) => { 
        if (severity < 4) {
            if (!symps.includes('Nenhum acima')) {
                setValue('severity', 4)
            }
        }
        setValue("lackOfAir", lackOfAir)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                    Notou a presença de algum destes sinais?
                    </h2>
                    <div>
                        <Checkbox id="lackOfAir-1" label="Ponta dos dedos ou lábios arroxeados" onChange={() => setLackOfAir(state => [...state, "Ponta dos dedos ou lábios arroxeados"])} />
                        <Checkbox id="lackOfAir-2" label="Dificuldade de fazer as coisas do dia a dia devido a fraqueza" onChange={() => setLackOfAir(state => [...state, "Dificuldade de fazer as coisas do dia a dia devido a fraqueza"])} />
                        <Checkbox id="lackOfAir-3" label="Falta de ar" onChange={() => setLackOfAir(state => [...state, "Falta de ar"])} />
                    </div>
                    <ButtonConfirm type="button" onClick={() => handle(["Nenhum acima"])}>Nenhuma acima</ButtonConfirm>
                    <ButtonConfirm type="button" onClick={() => handle(lackOfAir)}>Confirmar</ButtonConfirm>
                </AnimationContainer>
            </Content>
        </Container>
    )
};

export default LackOfAir;