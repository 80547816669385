import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const AnyOtherSymptom = ({ setValue, watch }) => {
  const severity = watch('severity') || 0
  const handle = (bool, weight) => {
    if (weight > severity) {
      setValue('severity', weight)
    }
    setValue('anotherSymptom', bool)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }
  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Você apresenta algum outro sintoma?</h2>
          <div>
            <Button type='button' onClick={() => handle('Sim', 3)}>
              Sim
            </Button>
            <Button type='button' onClick={() => handle('Não', 2)}>
              Não
            </Button>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}

export default AnyOtherSymptom
