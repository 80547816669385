import { useCallback } from "react";
import { useControllableState } from "./useControllableState";

export function useControllableModal({
  prop,
  defaultProp,
  onChangeOpen,
}) {
  const [isOpen, setIsOpen] = useControllableState({
    prop,
    defaultProp,
    onChange: onChangeOpen,
  });

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const setIsModalOpen = useCallback(
    (value) => {
      setIsOpen(value);
    },
    [setIsOpen]
  );

  return { isOpen: isOpen, openModal, closeModal, setIsModalOpen };
}