import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const WhenSymptomsStarted = ({ setValue }) => {
  const handle = (when) => {
    setValue('whenSymptomsStarted', when)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }
  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Faz quanto tempo que você tem esses sintomas?</h2>
          <div>
            <Button
              type='button'
              onClick={() => handle('Começaram há algumas horas')}
            >
              Começaram há algumas horas
            </Button>
            <Button
              type='button'
              onClick={() => handle('Começaram há alguns dias')}
            >
              Começaram há alguns dias
            </Button>
            <Button
              type='button'
              onClick={() => handle('Começaram há algumas semanas')}
            >
              Começaram há algumas semanas
            </Button>
            <Button
              type='button'
              onClick={() => handle('Começaram há alguns meses')}
            >
              Começaram há alguns meses
            </Button>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}

export default WhenSymptomsStarted
