import { useState } from 'react'
import Alcohol from '../../SharedQuestions/Alcohol'
import ChronicDisease from '../../SharedQuestions/ChronicDisease'
import EvacuationDifficulty from '../../SharedQuestions/EvacuationDifficulty'
import Fever from '../../SharedQuestions/Fever'
import HowAreFezes from '../../SharedQuestions/HowAreFezes'
import HowIsUrine from '../../SharedQuestions/HowIsUrine'
import HowWasBlood from '../../SharedQuestions/HowWasBlood'
import KindOfBellyPain from '../../SharedQuestions/KindOfBellyPain'
import NauseaOrVomit from '../../SharedQuestions/NauseaOrVomit'
import PainCharacteristic from '../../SharedQuestions/PainCharacteristic'
import PainIntensity from '../../SharedQuestions/PainIntensity'
import Smoker from '../../SharedQuestions/Smoker'
import SpecificMedicine from '../../SharedQuestions/SpecificMedicine'
import UrineDifficulty from '../../SharedQuestions/UrineDifficulty'
import WasThereBlood from '../../SharedQuestions/WasThereBlood'
import WhenSymptomsStarted from '../../SharedQuestions/WhenSymptomsStarted'
import Worsening from '../../SharedQuestions/Worsening'
import ButtonConfirm from '../../components/ButtonConfirm'
import HowPainStarted from '../../SharedQuestions/HowPainStarted'
import { Checkbox } from '../../components/Checkbox'
import { Spinner } from '../../components/Spinner'
import { AnimationContainer, Container, Content } from './styles'

const InferiorBelly = ({
  setValue,
  onSubmit,
  getValues,
  watch,
  isLoading,
  setIsLoading,
}) => {
  const [painLocation, setPainLocation] = useState([])
  const [liver, setLiver] = useState([])

  const pain = watch('painLocation') || false
  const when = watch('whenSymptomsStarted') || false
  const painCharacteristic = watch('painCharacteristic') || false
  const kindOfBellyPain = watch('kindOfBellyPain') || false
  const howPainStarted = watch('howPainStarted')
  const worsening = watch('bellyPainWorsening')
  const painIntensity = watch('painIntensity')
  const fever = watch('fever')
  const nausea = watch('nausea')
  const vomit = watch('vomit')
  const blood = watch('blood')
  const howWasBlood = watch('kindOfBlood')
  const evacuationDifficulty = watch('evacuationDifficulty')
  const fezes = watch('fezes')
  const urineDifficulty = watch('urineDifficulty')
  const howIsUrine = watch('howIsUrine')
  const alcohol = watch('alcohol')
  const specificMedicine = watch('specificMedicine')
  const smoker = watch('smoker')
  const liverDisease = watch('liver')
  const severity = watch('severity') || 0

  const handle = (key, value, weight) => {
    if (weight > severity) {
      setValue('severity', weight)
    }
    setValue(key, value)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  const checkBoxPain = (e, v) => {
    if (e.target?.checked) {
      setPainLocation((state) => [...state, v])
    } else {
      setPainLocation((state) => state.filter((e) => e !== v))
    }
  }

  const checkBoxLiver = (e, v) => {
    if (e.target?.checked) {
      setLiver((state) => [...state, v])
    } else {
      setLiver((state) => state.filter((e) => e !== v))
    }
  }

  return (
    <>
      <Container>
        <Content>
          <AnimationContainer>
            <h2>Qual é o local da dor?</h2>
            <div>
              <Checkbox
                id='infBellyPainLocation-1'
                label='Inferior esquerdo'
                onChange={(e) => checkBoxPain(e, 'Inferior esquerdo')}
              />
              <Checkbox
                id='infBellyPainLocation-2'
                label='Inferior centro'
                onChange={(e) => checkBoxPain(e, 'Inferior centro')}
              />
              <Checkbox
                id='infBellyPainLocation-3'
                label='Inferior direito'
                onChange={(e) => checkBoxPain(e, 'Inferior direito')}
              />
              <Checkbox
                id='infBellyPainLocation-4'
                label='Ao redor do umbigo'
                onChange={(e) => checkBoxPain(e, 'Ao redor do umbigo')}
              />
              <ButtonConfirm
                type='button'
                onClick={() => {
                  if (painLocation.length > 0)
                    handle('painLocation', painLocation, 0)
                }}
              >
                Confirmar
              </ButtonConfirm>
              {isLoading && <Spinner />}
            </div>
          </AnimationContainer>
        </Content>
      </Container>
      {pain && <WhenSymptomsStarted setValue={setValue} />}
      {when && <PainCharacteristic setValue={setValue} />}
      {painCharacteristic && <KindOfBellyPain setValue={setValue} />}
      {kindOfBellyPain && <HowPainStarted setValue={setValue} belly={true} watch={watch}/>}
      {howPainStarted && <Worsening setValue={setValue} />}
      {worsening && <PainIntensity setValue={setValue} watch={watch} />}
      {painIntensity && <Fever setValue={setValue} watch={watch} />}
      {fever && <NauseaOrVomit setValue={setValue} watch={watch} />}
      {vomit && <WasThereBlood setValue={setValue} watch={watch}/>}
      {blood === 'Sim' && <HowWasBlood setValue={setValue} />}
      {(nausea && <EvacuationDifficulty setValue={setValue} watch={watch} />) ||
        (howWasBlood && <EvacuationDifficulty setValue={setValue} watch={watch} />)}
      {evacuationDifficulty && <HowAreFezes setValue={setValue} watch={watch} belly={true}/>}
      {fezes && <UrineDifficulty setValue={setValue} watch={watch} />}
      {urineDifficulty && <HowIsUrine setValue={setValue} watch={watch} />}
      {howIsUrine && <Alcohol setValue={setValue} />}
      {alcohol && <SpecificMedicine setValue={setValue} watch={watch} />}
      {specificMedicine && <Smoker setValue={setValue} />}
      {smoker && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Possui alguma das doenças indicadas abaixo?</h2>
              <div>
                <Checkbox
                  id='liverDisease-1'
                  label='Síndrome do intestino irritável'
                  onChange={(e) =>
                    checkBoxLiver(e, 'Síndrome do intestino irritável')
                  }
                />
                <Checkbox
                  id='liverDisease-2'
                  label='Doença de Crohn'
                  onChange={(e) => checkBoxLiver(e, 'Doença de Crohn')}
                />
                <Checkbox
                  id='liverDisease-3'
                  label='Retocolite Ulcerativa'
                  onChange={(e) => checkBoxLiver(e, 'Retocolite Ulcerativa')}
                />
                <Checkbox
                  id='liverDisease-4'
                  label='Doença celíaca'
                  onChange={(e) => checkBoxLiver(e, 'Doença celíaca')}
                />
                <Checkbox
                  id='liverDisease-5'
                  label='Intolerância a lactose'
                  onChange={(e) => checkBoxLiver(e, 'Intolerância a lactose')}
                />
                <Checkbox
                  id='liverDisease-6'
                  label='Doença diverticular'
                  onChange={(e) => checkBoxLiver(e, 'Doença diverticular')}
                />
                <Checkbox
                  id='liverDisease-7'
                  label='Nenhuma doença diverticular'
                  onChange={(e) => checkBoxLiver(e, 'Nenhuma doença diverticular')}
                />

                <ButtonConfirm
                  type='button'
                  onClick={() => {
                    if (liver.length > 0) handle('liver', liver, 0)
                  }}
                >
                  Confirmar
                </ButtonConfirm>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {liverDisease && (
        <ChronicDisease
        watch={watch}
          setValue={setValue}
          getValues={getValues}
          onSubmit={onSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  )
}

export default InferiorBelly
