import Button from "../../components/Button";
import { AnimationContainer, Container, Content } from "./styles"; // estilos pro import

const Gender = ({ setValue }) => {
    const handle = (gender) => {
        setValue("gender", gender)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Qual o seu sexo?
                    </h2>
                    <div>
                        <Button type="button" onClick={() => handle("Masculino")}>Masculino</Button>
                        <Button type="button" onClick={() => handle("Feminino")}>Feminimo</Button>
                    </div>
                </AnimationContainer>
            </Content>
        </Container>
    )
};

export default Gender;