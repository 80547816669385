import { useState } from 'react'
import ButtonConfirm from '../../components/ButtonConfirm'
import { Checkbox } from '../../components/Checkbox'
import { AnimationContainer, Container, Content } from './styles'

const ExtraSymptoms = ({ setValue }) => {
  const [extraSymptoms, setExtraSymptoms] = useState([])

  const handle = (extraSymptoms) => {
    setValue('extraSymptomsAwnser', extraSymptoms)
    setValue('throatExtraSymptom', true)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  const checkBoxState = (e, v) => {
    if (e.target?.checked) {
      setExtraSymptoms((state) => [...state, v])
    } else {
      setExtraSymptoms((state) => state.filter((e) => e !== v))
    }
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Você tem alguma destas queixas relacionadas?</h2>
          <div>
            <Checkbox
              type='button'
              id='extraSymptom-1'
              label='Falta de ar'
              onChange={(e) => checkBoxState(e, 'Falta de ar')}
            />
            <Checkbox
              type='button'
              id='extraSymptom-2'
              label='Coriza, espirro ou coceira no nariz'
              onChange={(e) =>
                checkBoxState(e, 'Coriza, espirro ou coceira no nariz')
              }
            />
            <Checkbox
              type='button'
              id='extraSymptom-3'
              label='Náuses e/ou vômitos'
              onChange={(e) => checkBoxState(e, 'Náuses e/ou vômitos')}
            />
            <Checkbox
              type='button'
              id='extraSymptom-4'
              label='Tontura'
              onChange={(e) => checkBoxState(e, 'Tontura')}
            />
            <Checkbox
              type='button'
              id='extraSymptom-5'
              label='Dor em alguma região do rosto'
              onChange={(e) =>
                checkBoxState(e, 'Dor em alguma região do rosto')
              }
            />
            <Checkbox
              type='button'
              id='extraSymptom-6'
              label='Tosse'
              onChange={(e) => checkBoxState(e, 'Tosse')}
            />
            <Checkbox
              type='button'
              id='extraSymptom-7'
              label='Alteração visual'
              onChange={(e) => checkBoxState(e, 'Alteração visual')}
            />
            <Checkbox
              type='button'
              id='extraSymptom-8'
              label='Dor no olho'
              onChange={(e) => checkBoxState(e, 'Dor no olho')}
            />
            <Checkbox
              type='button'
              id='extraSymptom-9'
              label='Fotofobia'
              onChange={(e) => checkBoxState(e, 'Fotofobia')}
            />
            <ButtonConfirm
              type='button'
              onClick={() => handle(['Nenhuma acima'])}
            >
              Nenhuma acima
            </ButtonConfirm>
          </div>
          <ButtonConfirm
            type='button'
            onClick={() => {
              if (extraSymptoms.length > 0) handle(extraSymptoms)
            }}
          >
            Confirmar
          </ButtonConfirm>
        </AnimationContainer>
      </Content>
    </Container>
  )
}

export default ExtraSymptoms
