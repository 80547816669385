import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const Alcohol = ({ setValue }) => {
  const handle = (bool) => {
    setValue('alcohol', bool)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }
  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Você tomou álcool em excesso nos últimos dois dias?</h2>
          <div>
            <Button type='button' onClick={() => handle('Sim')}>
              Sim
            </Button>
            <Button type='button' onClick={() => handle('Não')}>
              Não
            </Button>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}
export default Alcohol
