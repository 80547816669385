import { useState } from 'react'
import ButtonConfirm from '../../components/ButtonConfirm'
import { Checkbox } from '../../components/Checkbox'
import { AnimationContainer, Container, Content } from './styles'

const BellyPainLocation = ({setValue}) => {
    const [infBellyPainLocation, setInfBellyPainLocation] = useState([])

    const handle = (key, value) => {
        setValue(key, value)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
        }, 200)
    }

    const checkBoxState = (e, v) => {
        if (e.target?.checked) {
            setInfBellyPainLocation((state) => [...state, v])
        } else {
            setInfBellyPainLocation((state) => state.filter((e) => e !== v))
        }
    }
    return (
        <Container>
            <Content>
            <AnimationContainer>
                <h2>Qual é o local da dor de barriga?</h2>
                <div>
                <Checkbox
                    id='infBellyPainLocation-1'
                    label='Inferior esquerdo'
                    onChange={(e) => checkBoxState(e, 'Inferior esquerdo')}
                />
                <Checkbox
                    id='infBellyPainLocation-2'
                    label='Inferior centro'
                    onChange={(e) => checkBoxState(e, 'Inferior centro')}
                />
                <Checkbox
                    id='infBellyPainLocation-3'
                    label='Inferior direito'
                    onChange={(e) => checkBoxState(e, 'Inferior direito')}
                />
                <Checkbox
                    id='infBellyPainLocation-4'
                    label='Ao redor do umbigo'
                    onChange={(e) => checkBoxState(e, 'Ao redor do umbigo')}
                />

                </div>
                <ButtonConfirm
                    type='button'
                    onClick={() => {
                    if (infBellyPainLocation.length > 0)
                        handle('infBellyPainLocation', infBellyPainLocation)
                    }}
                >
                    Confirmar
                </ButtonConfirm>
            </AnimationContainer>
            </Content>
        </Container>
    )
   
}

export default BellyPainLocation
