import styled, { css } from "styled-components";

export const Label = styled.label`
  ${() => css`
    font-size: 1rem;
    color:"#0c0d0d";
    user-select: none;
    font-family: "Roboto Mono", monospace;
    border-radius: 8px;
    cursor: pointer;
  `}
`;

export const Input = styled.input`
  background: ${(props) => (props.whiteSchema ? "#f5f5f5" : "#0c0d0d")};
  color: ${(props) => (props.whiteSchema ? "#0c0d0d" : "#f5f5f5")};
  height: 16px;
  border-radius: 8px;
  border: 2px solid var(--black);
  font-family: "Roboto Mono", monospace;
  margin-top: 16px;
  margin-right: 8px;
  transition: 0.5s;
  :hover {
    border: 2px solid var(--orange);
    cursor: pointer;
  }
  width: 16px;
`

