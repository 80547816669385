import styled, { css } from "styled-components";

export const Container = styled.div`
  text-align: left;
  width: 60%;
  div {
    span {
      color: var(--red);
    }
    color: rgb(153, 153, 153);
    font-size: 1.125rem;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    display: flex;
  }
`;

export const InputContainer = styled.div`
  background: var(--white);
  border-radius: 5px;
  border: 1px solid var(--gray);
  padding: 1rem;
  width: 100%;
  display: flex;
  transition: 0.4s;
  box-shadow: rgb(0 0 0 / 10%) 1px 1px 8px;
  :hover {
    outline: none;
    border: 1px solid rgb(243, 148, 54);
    box-shadow: rgb(243 148 54) 0px 0px 5px 0px;
  }

  label {
    color: rgb(153, 153, 153);
    font-size: 1.125rem;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    display: block;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: var(--red);
      svg {
        color: var(--red);
      }
    `}

  input {
    height: 2.5rem;
    width: 100%;
    background: transparent;
    align-items: center;
    flex: 1;
    border: 0;
    color: var(--black);
    &::placeholder {
      color: var(--gray);
    }
  }
  svg {
    margin-right: 16px;
    margin-top: 10px;
    color: var(--green);
  }
`;
