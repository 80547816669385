/* eslint-disable no-unused-expressions */
import { Checkbox as CheckboxField } from "../components/Checkbox";
import { RegisterField } from "./register";

export const Checkbox = ({ name, ...props }) => {

  return (
    <RegisterField
      name={name}
      render={({ ref, value, error, ...fields }) => (
        <CheckboxField
          {...fields}
          {...props}
          name={name}
          ref={ref}
          checked={!!value}
          errorId={error}
        />
      )}
    />
  );
};
