import axios from "axios";
// import env from "react-dotenv";

// dev https://dev-apigateway.institutolaura.net/LcScreening
// prd guarapuava https://apigateway.institutolaura.net/LcScreening

const api = axios.create({
  baseURL: "https://apigateway.institutolaura.net/LcScreening",
});

export default api;
