import ButtonConfirm from "../../components/ButtonConfirm";
import Error from "../../components/ErrorComponent";
import { Spinner } from "../../components/Spinner";
import { Form } from "../../FormRoot";
import { AnimationContainer, Container, Content } from "./styles"; // estilos pro import

const CPF = ({ handleClick, control, InputMask, error, isLoading, isDisabled }) => {
    
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    {error && <Error error={error}/>} 
                    <Form.Text
                        placeholder="000.000.000-00"
                        name="cpf"
                        label="Informe seu CPF"
                        as={InputMask}
                        mask="999.999.999-99"
                        control={control}
                        disabled={isDisabled}
                    />
                    <div style={{display: "flex", flexDirection: "col"}}>
                        <ButtonConfirm type="button" onClick={handleClick}>Confirmar</ButtonConfirm>
                        { isLoading && <Spinner /> }
                    </div>
                    
                </AnimationContainer>
            </Content>
        </Container>
    )
};


export default CPF;