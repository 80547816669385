import Button from "../../components/Button";
import { AnimationContainer, Container, Content } from "./styles";


const Covid = ({ setValue }) => {
    const handle = (bool) => {
        if (bool === "Sim") {
            setValue('severity', 4)
            setValue("disease", "covid")
        }
        setValue("covid", bool)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Atenção! Você apresenta sintomas sugestivos de Síndrome Respiratória ou COVID-19 como tosse, febre, falta de ar e/ou alteração no paladar e no olfato?
                    </h2>
                    <div>
                        <Button type="button" onClick={() => handle("Sim")}>Sim, estou com suspeita de COVID-19</Button>
                        <Button type="button" onClick={() => handle("Não")}>Não tenho esses sintomas</Button>
                    </div>
                </AnimationContainer>
            </Content>
        </Container>
    )
};


export default Covid;