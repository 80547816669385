import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const HowWasBlood = ({ setValue }) => {
  const handle = (howWasBlood) => {
    setValue('kindOfBlood', howWasBlood)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Como o sangue aparecia no vômito?</h2>
          <div>
            <Button type='button' onClick={() => handle('Vermelho vivo')}>
              Vermelho vivo
            </Button>
            <Button type='button' onClick={() => handle('Escurecido')}>
              Escurecido
            </Button>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}
export default HowWasBlood
