import ButtonConfirm from "../../components/ButtonConfirm";
import { Form } from "../../FormRoot";
import { AnimationContainer, Container, Content } from "./styles"; // estilos pro import
import {FaWhatsappSquare} from "react-icons/fa"
import { useState } from "react";
import Error from "../../components/ErrorComponent";

const User = ({ user, setValue, watch, control, InputMask }) => {
    const [addressValidation, setAddressValidation] = useState(false);
    const error = addressValidation
    const userName = watch("name")  || user["name"]
    const userPhone = watch("phone") || user["phone"]
    // const userEmail = watch("email") || user["email"]
    const userBirthDate = watch("birthDate") || user["birthDate"]
    const userAddress = watch("address") || user["address"]
    const userAddressNumber = watch("addressNumber") || user["addressNumber"]
    const userCep = watch("cep") || user["cep"]
    const userCity = watch("city") || user["city"]
    const userState = watch("state") || user["state"]
    let address = false
    if (user['cep'] === null || user['cep'].trim === "") {
        address = true
    }
    const handle = () => {
        const updatedUser = {
            name: userName, 
            phone: userPhone, 
            // email: userEmail, 
            birthDate: userBirthDate,
            address: userAddress,
            addressNumber: userAddressNumber,
            cep: userCep,
            city: userCity,
            state: userState
        }
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
        setValue("updatedUser", updatedUser)
        if (
                updatedUser.name !== null 
                // && 
                // updatedUser.email !== null 
                && 
                updatedUser.birthDate !== null 
                && 
                updatedUser.phone !== null
                && 
                updatedUser.address !== null && updatedUser.address !== ""
                && 
                updatedUser.addressNumber !== null && updatedUser.addressNumber !== ""
                && 
                updatedUser.cep !== null && updatedUser.cep !== ""
                && 
                updatedUser.city !== null && updatedUser.city !== ""
                && 
                updatedUser.state !== null && updatedUser.state !== ""
            )   {
                    setAddressValidation(false)
                    setValue("userUpdated", true)
                }
            else {
                setAddressValidation('Preencha todos os campos de endereço')
            }
        console.log(updatedUser)
    }
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>Confirme seu cadastro</h2>
                    <Form.Text
                        label="Nome completo"
                        placeholder="Nome completo"
                        name="name"
                        defaultValue={user["name"]}
                    />
                    <Form.Text
                        label="Informe aqui seu Whatsapp (celular)"
                        placeholder="Informe aqui seu Whatsapp (celular)"
                        name="phone"
                        defaultValue={user["phone"]}
                        as={InputMask}
                        mask="(99) 99999-9999"
                        control={control}
                        icon={FaWhatsappSquare}
                    />
                    <Form.Text
                        label="Data de nascimento"
                        placeholder="Data de nascimento"
                        name="birthDate"
                        defaultValue={user["birthDate"]}
                        as={InputMask}
                        mask="99/99/9999"
                        control={control}
                    />
                    <Form.Text
                        label="Rua"
                        placeholder="Rua"
                        name="address"
                        defaultValue={user["address"]}
                        disabled={address}
                    />
                    <Form.Text
                        label="Número"
                        placeholder="Número"
                        name="addressNumber"
                        defaultValue={user["addressNumber"]}
                        disabled={address}
                    />
                    <Form.Text
                        label="Cidade"
                        placeholder="Cidade"
                        name="city"
                        defaultValue={user["city"]}
                        disabled={address}
                    />
                    <Form.Text
                        label="Estado"
                        placeholder="Estado"
                        name="state"
                        defaultValue={user["state"]}
                        disabled={address}
                    />
                    <Form.Text
                        label="CEP"
                        placeholder="Cep"
                        name="cep"
                        defaultValue={user["cep"]}
                        disabled={address}
                        as={InputMask}
                        mask="99999-999"
                        control={control}
                    />
                    {/* <Form.Text
                        label="E-mail"
                        placeholder="E-mail"
                        name="email"
                        defaultValue={user["email"]}
                    /> */}
                    {error && <Error error={error}/>} 
                    <ButtonConfirm type="button" onClick={() => handle()}>Confirmar</ButtonConfirm>
                </AnimationContainer>
            </Content>
        </Container>
    )
};


export default User;