// import Button from "../components/Button";
import ChronicDisease from '../../SharedQuestions/ChronicDisease'
import FluSymptoms from '../../SharedQuestions/FluSymptoms'
import GroupIdentification from '../../SharedQuestions/GroupIdentification'
import LackOfAir from '../../SharedQuestions/LackOfAir'
import Travelled from '../../SharedQuestions/Travelled'

const CovidProtocol = ({
  setValue,
  watch,
  getValues,
  isLoading,
  setIsLoading,
  onSubmit,
}) => {
  const fluSymptoms = watch('fluSymptoms')
  const travelled = watch('travelled')
  const groupIdentification = watch('groupIdentification')
  const lackOfAir = watch('lackOfAir')

  return (
    <>
      <FluSymptoms setValue={setValue} isCovidProtocol={true} watch={watch} />
      {fluSymptoms && <Travelled setValue={setValue} watch={watch} />}
      {travelled && <GroupIdentification setValue={setValue} watch={watch} />}
      {groupIdentification && <LackOfAir setValue={setValue} watch={watch} />}
      {lackOfAir && (
        <ChronicDisease
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          onSubmit={onSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  )
}

export default CovidProtocol
