import { useState } from 'react'
import ButtonConfirm from '../../components/ButtonConfirm'
import { Checkbox } from '../../components/Checkbox'
import { AnimationContainer, Container, Content } from './styles'

const HowAreFezes = ({ setValue, watch, belly }) => {
  const [fezes, setFezes] = useState([])
  const severity = watch('severity') || 0

  const handle = (fezes, weight) => {
    if (belly === true) {
      if (fezes.includes("Presença de sangue") || fezes.includes("Presença de muco") || fezes.includes("Presença de pus")) {
        setValue('severity', 4)
      }
    }
       
    else if (weight > severity) {
      setValue('severity', weight)
    }
    setValue('fezes', fezes)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  const checkBoxState = (e, v) => {
    if (e.target?.checked) {
      setFezes((state) => [...state, v])
    } else {
      setFezes((state) => state.filter((e) => e !== v))
    }
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>E como estão as fezes?</h2>
          <div>
            <Checkbox
              type='button'
              id='howAreFezes-1'
              label='Aquosas e em maior frequência que o habitual'
              onChange={(e) =>
                checkBoxState(e, 'Aquosas e em maior frequência que o habitual')
              }
            />
            <Checkbox
              type='button'
              id='howAreFezes-2'
              label='Presença de sangue'
              onChange={(e) => checkBoxState(e, 'Presença de sangue')}
            />
            <Checkbox
              type='button'
              id='howAreFezes-3'
              label='Presença de muco'
              onChange={(e) => checkBoxState(e, 'Presença de muco')}
            />
            <Checkbox
              type='button'
              id='howAreFezes-4'
              label='Presença de pus'
              onChange={(e) => checkBoxState(e, 'Presença de pus')}
            />
            <Checkbox
              type='button'
              id='howAreFezes-5'
              label='Estão escurecidas, quase negras'
              onChange={(e) =>
                checkBoxState(e, 'Estão escurecidas, quase negras')
              }
            />
            <Checkbox
              type='button'
              id='howAreFezes-6'
              label='Estão branca como cola de vidraceiro'
              onChange={(e) =>
                checkBoxState(e, 'Estão branca como cola de vidraceiro')
              }
            />
            <ButtonConfirm type='button' onClick={() => handle(['Nenhuma'], 0)}>
              Nenhuma
            </ButtonConfirm>
          </div>
            <ButtonConfirm
              type='button'
              onClick={() => {
                if (fezes.length > 0) handle(fezes, 3)
              }}
            >
              Confirmar
            </ButtonConfirm>
        </AnimationContainer>
      </Content>
    </Container>
  )
}
export default HowAreFezes
