import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const PainCharacteristic = ({ setValue }) => {
  const handle = (painCharacteristic) => {
    setValue('painCharacteristic', painCharacteristic)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }
  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Qual é a característica da dor?</h2>
          <div>

          <Button type='button' onClick={() => handle('intermitente')}>
            A dor é intermitente, isto é, vai e vem
          </Button>
          <Button type='button' onClick={() => handle('Contínua')}>
            A dor é contínua
          </Button>
          <Button type='button' onClick={() => handle('Nenhuma acima')}>
            Nenhuma acima
          </Button>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}

export default PainCharacteristic
