import { forwardRef } from "react";
import { RegisterField } from "./register";
import Input from "../components/Input"


export const Text = forwardRef(({ name, control, ...textFieldProps }, ref) => {
    return (
        <RegisterField
            name={name}
            control={control}
            render={(values) => {
                return <Input type="text" {...textFieldProps} {...values} name={name} />;
            }}
        />
    );
});

Text.displayName = "Text";