import React, { useState } from 'react'
import ButtonConfirm from '../../components/ButtonConfirm'
import { Spinner } from '../../components/Spinner'
import { Checkbox } from '../../components/Checkbox'
import { AnimationContainer, Container, Content } from './styles'

const HowCanIHelp = ({
  setValue,
  onSubmit,
  getValues,
  isLoading,
  setIsLoading,
}) => {
  const [questionsOrInfo, setQuestionsOrInfo] = useState([])

  const handle = (questionsOrInfo) => {
    const clientId = 2; // deixar dinâmico
    setValue('questionsOrInfo', questionsOrInfo)
    setValue('severity', 1)
    setValue('clientId', clientId)
    setIsLoading(true)
    const form = getValues()
    onSubmit(form)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  const checkBoxState = (e, v) => {
    if (e.target?.checked) {
      setQuestionsOrInfo((state) => [...state, v])
    } else {
      setQuestionsOrInfo((state) => state.filter((e) => e !== v))
    }
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Como posso ajudar?</h2>
          <div>
            {/* <Checkbox
              type='button'
              id='howCanIHelp-1'
              label='Receitas'
              onChange={(e) => checkBoxState(e, 'Receitas ou Renovação de Receitas')}
            />
            <Checkbox
              type='button'
              id='howCanIHelp-2'
              label='Exames'
              onChange={(e) => checkBoxState(e, 'Exames')}
            /> */}
            <Checkbox
              type='button'
              id='howCanIHelp-3'
              label='Atestado'
              onChange={(e) => checkBoxState(e, 'Atestado')}
            />
            <Checkbox
              type='button'
              id='howCanIHelp-4'
              label='Dúvidas'
              onChange={(e) => checkBoxState(e, 'Dúvidas')}
            />
            <Checkbox
              type='button'
              id='howCanIHelp-5'
              label='Falar com atendente'
              onChange={(e) => checkBoxState(e, 'Falar com atendente')}
            />
            <Checkbox
              type='button'
              id='howCanIHelp-6'
              label='Aleitamento materno'
              onChange={(e) => checkBoxState(e, 'Aleitamento materno')}
            />
            <div id='submit'>
              <ButtonConfirm
                type='button'
                onClick={() => {
                  if (questionsOrInfo.length > 0) handle(questionsOrInfo)
                }}
              >
                Confirmar
              </ButtonConfirm>
              {isLoading && <Spinner />}
            </div>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}

export default HowCanIHelp
