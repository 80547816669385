import { useState } from 'react'
import ButtonConfirm from '../../components/ButtonConfirm'
import { Checkbox } from '../../components/Checkbox'
import { AnimationContainer, Container, Content } from './styles'

const EvacuationDifficulty = ({ setValue, watch }) => {
  const [evacuationDiff, setEvacuationDiff] = useState([])
  const severity = watch('severity') || 0

  const handle = (evacuationDiff) => {
    if (severity < 2) {
      if (evacuationDiff[0] === "Nenhuma") {
        setValue('severity', 0)
      }
      else {
        setValue('severity', 2)
      }
    }
    
    setValue('evacuationDifficulty', evacuationDiff)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  const checkBoxState = (e, v) => {
    if (e.target?.checked) {
      setEvacuationDiff((state) => [...state, v])
    } else {
      setEvacuationDiff((state) => state.filter((e) => e !== v))
    }
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Você apresenta algumas dessas dificuldades para evacuar?</h2>
          <div>
            <Checkbox
              type='button'
              id='evacuationDiff-1'
              label='Evacuação Dolorosa'
              onChange={(e) => checkBoxState(e, 'Inferior esquerdo')}
            />
            <Checkbox
              type='button'
              id='evacuationDiff-2'
              label='Preciso fazer força para evacuar'
              onChange={(e) =>
                checkBoxState(e, 'Preciso fazer força para evacuar')
              }
            />
            <Checkbox
              type='button'
              id='evacuationDiff-3'
              label='Mantenho vontade de evacuar mesmo após ter ido ao banheiro'
              onChange={(e) =>
                checkBoxState(
                  e,
                  'Mantenho vontade de evacuar mesmo após ter ido ao banheiro'
                )
              }
            />
          </div>
            <ButtonConfirm type='button' onClick={() => handle(['Nenhuma'])}>
              Nenhuma
            </ButtonConfirm>
            <ButtonConfirm
              type='button'
              onClick={() => {
                if (evacuationDiff.length > 0) handle(evacuationDiff)
              }}
            >
              Confirmar
            </ButtonConfirm>
        </AnimationContainer>
      </Content>
    </Container>
  )
}
export default EvacuationDifficulty
