import { yupResolver } from "@hookform/resolvers/yup";
import { useImperativeHandle } from "react";
import { useForm, FormProvider } from "react-hook-form";

const Root = ({
    children,
    onSubmit,
    validationSchema,
    defaultValues,
    innerRef
}) => {
    const { handleSubmit, ...form } = useForm({
        ...(validationSchema && { resolver: yupResolver(validationSchema) }),
        defaultValues,
        mode: "all",
        reValidateMode: "onChange",
    });

    useImperativeHandle(innerRef, () => form);

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit((value) => onSubmit(value))}>
                {typeof children === 'function' ? children(form) : children}
            </form>
        </FormProvider>
    )
}

export default Root;
