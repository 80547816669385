import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const SpecificMedicine = ({ setValue, supbelly, watch }) => {
  const severity = watch('severity') || 0
  const handle = (specificMedicine) => {
    if (supbelly && specificMedicine !== 'Nenhum acima') {
      if (severity < 3) {
        setValue('severity', 3)
      }
    }
    setValue('specificMedicine', specificMedicine)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }
  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Você usa algum dos remédios abaixo?</h2>
          <div>
            <Button
              type='button'
              onClick={() =>
                handle(
                  'Antiinflamatório (p. ex: Ibuprofeno, nimesulida, diclofenaco)'
                )
              }
            >
              Antiinflamatório (p. ex: Ibuprofeno, nimesulida, diclofenaco)
            </Button>
            <Button type='button' onClick={() => handle('Aspirina')}>
              Aspirina
            </Button>
            <Button type='button' onClick={() => handle('Nenhum acima')}>
              Nenhum acima
            </Button>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}
export default SpecificMedicine
