import ChronicDisease from '../../SharedQuestions/ChronicDisease'
import Smoker from '../../SharedQuestions/Smoker'
import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const DepressionProtocol = ({
  setValue,
  watch,
  getValues,
  isLoading,
  setIsLoading,
  onSubmit,
}) => {
  const isCalm = watch('isCalm')
  const dryMouth = watch('dryMouth')
  const positiveFeelings = watch('positiveFeelings')
  const breathing = watch('breathing')
  const iniciative = watch('iniciative')
  const overreact = watch('overreact')
  const tremors = watch('tremors')
  const nervousness = watch('nervousness')
  const concern = watch('concern')
  const noDesire = watch('noDesire')
  const agitation = watch('agitation')
  const relaxation = watch('relaxation')
  const mood = watch('mood')
  const tolerance = watch('tolerance')
  const panic = watch('panic')
  const enthusiasm = watch('enthusiasm')
  const selfValue = watch('selfValue')
  const sensitive = watch('sensitive')
  const heartRate = watch('heartRate')
  const fear = watch('fear')
  const lifeMeaning = watch('lifeMeaning')
  const isAlcoholic = watch('isAlcoholic')
  const isSmoker = watch('smoker')
  const severity = watch('severity') || 0

  const handle = (key, value, weight) => {
    if (weight > severity) {
      setValue('severity', weight)
    }
    setValue(key, value)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  return (
    <>
      <Container>
        <Content>
          <AnimationContainer>
            <h2>Na última semana, achei difícil me acalmar</h2>
            <div>
              <Button
                type='button'
                onClick={() =>
                  handle('isCalm', 'Não se aplicou de maneira alguma', 2)
                }
              >
                Não se aplicou de maneira alguma
              </Button>
              <Button
                type='button'
                onClick={() => handle('isCalm', 'Aplicou-se em pequeno grau', 1)}
              >
                Aplicou-se em pequeno grau
              </Button>
              <Button
                type='button'
                onClick={() =>
                  handle(
                    'isCalm',
                    'Aplicou-se em grau considerável ou por uma boa parte do tempo',
                    3
                  )
                }
              >
                Aplicou-se em grau considerável ou por uma boa parte do tempo
              </Button>
              <Button
                type='button'
                onClick={() =>
                  handle('isCalm', 'Aplicou-se muito ou na maioria do tempo', 4)
                }
              >
                Aplicou-se muito ou na maioria do tempo
              </Button>
            </div>
          </AnimationContainer>
        </Content>
      </Container>

      {isCalm && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Senti minha boca seca</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('dryMouth', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('dryMouth', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'dryMouth',
                      'Aplicou-se em grau considerável ou por uma boa parte do tempo', 
                      0
                    )
                  }
                >
                  Aplicou-se em grau considerável ou por uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'dryMouth',
                      'Aplicou-se muito ou na maioria do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {dryMouth && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Não consegui vivenciar nenhum sentimento positivo</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'positiveFeelings',
                      'Não se aplicou de maneira alguma',
                      0
                    )
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('positiveFeelings', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'positiveFeelings',
                      'Aplicou-se em grau considerável ou por uma boa parte do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se em grau considerável ou por uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'positiveFeelings',
                      'Aplicou-se muito ou na maioria do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {positiveFeelings && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>
                Tive dificuldade em respirar em alguns momentos (ex. respiração ofegante, falta de ar, sem ter feito nenhum esforço físico)
              </h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('breathing', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('breathing', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'breathing',
                      'Aplicou-se em grau considerável ou por uma boa parte do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se em grau considerável ou por uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'breathing',
                      'Aplicou-se muito ou na maioria do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {breathing && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Achei difícil ter iniciativa para fazer as coisas</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('iniciative', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('iniciative', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'iniciative',
                      'Aplicou-se em grau considerável ou por uma boa parte do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se em grau considerável ou por uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'iniciative',
                      'Aplicou-se muito ou na maioria do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {iniciative && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>
                Tive a tendência de reagir de forma exagerada às situações
              </h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('overreact', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('overreact', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'overreact',
                      'Aplicou-se em grau considerável ou por uma boa parte do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se em grau considerável ou por uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'overreact',
                      'Aplicou-se muito ou na maioria do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {overreact && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Senti tremores (ex. nas mãos)</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('tremors', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('tremors', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'tremors',
                      'Aplicou-se em grau considerável ou por uma boa parte do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se em grau considerável ou por uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('tremors', 'Aplicou-se muito ou na maioria do tempo', 0)
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {tremors && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Senti que estava sempre nervoso</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('nervousness', 'Não se aplicou de maneira alguma',0 )
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('nervousness', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'nervousness',
                      'Aplicou-se em grau considerável ou por uma boa parte do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se em grau considerável ou por uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'nervousness',
                      'Aplicou-se muito ou na maioria do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {nervousness && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>
                Preocupei-me com situações em que eu pudesse entrar em pânico e parecesse ridículo(a)
              </h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('concern', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('concern', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'concern',
                      'Aplicou-se em grau considerável ou por uma boa parte do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se em grau considerável ou por uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('concern', 'Aplicou-se muito ou na maioria do tempo', 0)
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {concern && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Senti que não tinha nada a desejar</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('noDesire', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('noDesire', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'noDesire',
                      'Aplicou-se em grau considerável ou por uma boa parte do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se em grau considerável ou por uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'noDesire',
                      'Aplicou-se muito ou na maioria do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {noDesire && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Senti-me agitado</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('agitation', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('agitation', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'agitation',
                      'Aplicou-se em grau considerável ou por uma boa parte do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se em grau considerável ou por uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'agitation',
                      'Aplicou-se muito ou na maioria do tempo',
                      4
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {agitation && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Achei difícil relaxar</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('relaxation', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('relaxation', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'relaxation',
                      'Aplicou-se em grau considerável ou por uma boa parte do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se em grau considerável ou por uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'relaxation',
                      'Aplicou-se muito ou na maioria do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {relaxation && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Senti-me depressivo(a) e sem ânimo</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('mood', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('mood', 'Aplicou-se em pequeno grau', 0)}
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('mood', 'Aplicou-se em uma boa parte do tempo', 0)
                  }
                >
                  Aplicou-se em uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('mood', 'Aplicou-se muito ou na maioria do tempo', 0)
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {mood && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>
                Fui intolerante com as coisas que me impediam de continuar o que eu estava fazendo
              </h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('tolerance', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('tolerance', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('tolerance', 'Aplicou-se em uma boa parte do tempo', 0)
                  }
                >
                  Aplicou-se em uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'tolerance',
                      'Aplicou-se muito ou na maioria do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {tolerance && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Senti que ia entrar em pânico</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('panic', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('panic', 'Aplicou-se em pequeno grau', 0)}
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('panic', 'Aplicou-se em uma boa parte do tempo', 3)
                  }
                >
                  Aplicou-se em uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('panic', 'Aplicou-se muito ou na maioria do tempo', 3)
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {panic && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Não consegui me entusiasmar com nada</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('enthusiasm', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('enthusiasm', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('enthusiasm', 'Aplicou-se em uma boa parte do tempo', 0)
                  }
                >
                  Aplicou-se em uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'enthusiasm',
                      'Aplicou-se muito ou na maioria do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {enthusiasm && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Senti que não tinha valor como pessoa</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('selfValue', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('selfValue', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('selfValue', 'Aplicou-se em uma boa parte do tempo', 0)
                  }
                >
                  Aplicou-se em uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'selfValue',
                      'Aplicou-se muito ou na maioria do tempo',
                      3
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {selfValue && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Senti que estava um pouco emotivo/sensível demais</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('sensitive', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('sensitive', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('sensitive', 'Aplicou-se em uma boa parte do tempo', 0)
                  }
                >
                  Aplicou-se em uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'sensitive',
                      'Aplicou-se muito ou na maioria do tempo',
                      0
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {sensitive && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>
                Sabia que meu coração estava alterado mesmo não tendo feito nenhum esforço físico (ex. aumento da frequência cardíaca, disritmia cardíaca)
              </h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('heartRate', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('heartRate', 'Aplicou-se em pequeno grau', 0)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('heartRate', 'Aplicou-se em uma boa parte do tempo', 3)
                  }
                >
                  Aplicou-se em uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'heartRate',
                      'Aplicou-se muito ou na maioria do tempo',
                      4
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {heartRate && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Senti medo sem motivo</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('fear', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('fear', 'Aplicou-se em pequeno grau', 0)}
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('fear', 'Aplicou-se em uma boa parte do tempo', 0)
                  }
                >
                  Aplicou-se em uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('fear', 'Aplicou-se muito ou na maioria do tempo', 0)
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {fear && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Senti que a vida não tinha sentido (primeiro lugar)</h2>
              <div>
                <Button
                  type='button'
                  onClick={() =>
                    handle('lifeMeaning', 'Não se aplicou de maneira alguma', 0)
                  }
                >
                  Não se aplicou de maneira alguma
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('lifeMeaning', 'Aplicou-se em pequeno grau', 3)
                  }
                >
                  Aplicou-se em pequeno grau
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'lifeMeaning',
                      'Aplicou-se em uma boa parte do tempo',
                      4
                    )
                  }
                >
                  Aplicou-se em uma boa parte do tempo
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle(
                      'lifeMeaning',
                      'Aplicou-se muito ou na maioria do tempo',
                      4
                    )
                  }
                >
                  Aplicou-se muito ou na maioria do tempo
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {lifeMeaning && <Smoker setValue={setValue} watch={watch} />}
      {isSmoker && (
        <Container>
          <Content>
            <AnimationContainer>
              <h2>Você é alcoólatra ou faz uso de bebida com álcool?</h2>
              <div>
                <Button
                  type='button'
                  onClick={() => handle('isAlcoholic', 'Sim', 0)}
                >
                  Sim
                </Button>
                <Button
                  type='button'
                  onClick={() => handle('isAlcoholic', 'Ex-alcoólatra', 0)}
                >
                  Ex-alcoólatra
                </Button>
                <Button
                  type='button'
                  onClick={() =>
                    handle('isAlcoholic', 'Não. Nunca tive o hábito de beber', 0)
                  }
                >
                  Não. Nunca tive o hábito de beber
                </Button>
              </div>
            </AnimationContainer>
          </Content>
        </Container>
      )}
      {isAlcoholic && (
        <ChronicDisease
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          onSubmit={onSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  )
}

export default DepressionProtocol
