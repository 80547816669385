import { Container, InputContainer } from "./styles";
import InputMask from "react-input-mask"

const Input = ({ label, icon: Icon, register, error, control, ...rest }) => {
  return (
    <Container>
      <div>
        {label} {!!error && <span> - {error} </span>}
      </div>

      <InputContainer isErrored={!!error}>
        {Icon && <Icon size={20} />}
        <InputMask {...rest} />
      </InputContainer>
    </Container>
  );
};

export default Input;
