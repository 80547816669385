import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    :root {
        --white: #f9f9f9;
        --vanilla: #f1ede0;
        --black : #0c0d0d;
        --orange: #F6911E;
        --gray:  #999999;
        --red: #c53030;
        --yellow: #e3bd00;
        --blue: blue;
        --green: green;
        --waiting: #999999;
    }

    body {
        background: var(--white);
        color: var(--black);
    }
    
    body, input, button {
        font-family:  "Roboto",sans-serif;
        font-size: 1rem;
    }

    button:disabled,
    button[disabled]{
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
    }

    h1,h2,h3,h4,h5,h6 {
        font-family: "Roboto",sans-serif;
        font-weight: 400;
        align-self: flex-start;
    }

    h2 {
        color: #202020;
        font-size: 1.25rem;
        font-family: "Roboto",sans-serif;
        font-weight: 900;
        margin: 0 0 29px;
    }

    button {
        cursor: pointer;
    }

    a {
        text-decoration: underline;
        color: var(--white);
    }

    input[type='checkbox'] {
        margin-top:0;
        accent-color: gray;
    }
`;
