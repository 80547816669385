import { useState } from "react";
import ButtonConfirm from "../../components/ButtonConfirm";
import { Checkbox } from "../../components/Checkbox";
import { AnimationContainer, Container, Content } from "./styles";

const GroupIdentification = ({ setValue, watch }) => {
    const severity = watch('severity') || 0
    const [groupIdentification, setGroupIdentification] = useState([])
    
    const handle = (group) => { 
        if (severity < 3) {
            if (group.includes('Tem otite de repetição')) {
                setValue('severity', 3)
            }
        }
        setValue("groupIdentification", group)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Você se identifica com algum dos grupos abaixo?
                    </h2>
                    <div>
                        <Checkbox id="groupIdentification-1" label="Gestante" onChange={() => setGroupIdentification(state => [...state, "Gestante"])} />
                        <Checkbox id="groupIdentification-2" label="Idade menor do que 1 ano" onChange={() => setGroupIdentification(state => [...state, "Idade menor do que 1 ano"])} />
                        <Checkbox id="groupIdentification-3" label="Fumante (cigarro, charuto, narguilé, etc)" onChange={() => setGroupIdentification(state => [...state, "Fumante"])} />
                        <Checkbox id="groupIdentification-4" label="Idade igual ou maior do que 70 anos" onChange={() => setGroupIdentification(state => [...state, "Idade igual ou maior do que 70 anos"])} />
                        <Checkbox id="groupIdentification-5" label="Profissionais da saude" onChange={() => setGroupIdentification(state => [...state, "Profissionais de saude"])} />
                        <Checkbox id="groupIdentification-6" label="Nadou em piscina, mar ou rio nas últimas duas semanas" onChange={() => setGroupIdentification(state => [...state, "Nadou em piscina"])} />
                        <Checkbox id="groupIdentification-7" label="Usa rotineiramente cotonete" onChange={() => setGroupIdentification(state => [...state, "Usa rotineiramente cotonete"])} />
                        <Checkbox id="groupIdentification-8" label="Tem otite de repetição" onChange={() => setGroupIdentification(state => [...state, "Tem otite de repetição"])} />
                        <Checkbox id="groupIdentification-9" label="Outros" onChange={() => setGroupIdentification(state => [...state, "Outros"])} />
                    </div>
                    <ButtonConfirm type="button" onClick={() => handle(["Nenhum acima"])}>Nenhuma acima</ButtonConfirm>
                    <ButtonConfirm type="button" onClick={() => handle(groupIdentification)}>Confirmar</ButtonConfirm>
                </AnimationContainer>
            </Content>
        </Container>
    )
};

export default GroupIdentification;