import { Controller } from "react-hook-form";
import InputMask from "react-input-mask"

export function RegisterField({ name, render, control }) {
  return (
    <Controller
      name={name}
      as={InputMask}
      control={control}
      render={({ field, fieldState }) =>
        render({
          name,
          value: field.value,
          onChange: field.onChange,
          onBlur: field.onBlur,
          error: fieldState.error?.message,
          ref: field.ref,
        })
      }
    />
  );
}
