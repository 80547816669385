import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
`;

export const Background = styled.div`
  @media (min-width: 1100px) {
    flex: 1;
    background: no-repeat center, var(--black);
    background-size: contain;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px)        
    }
    to {
        opacity: 1;
        transform: translateX(0px)
    }
`;


export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${appearFromLeft} 1s;
  
  h2 {
    margin: 0 0 29px;
  }
  div {
    display: grid;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 130vh;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    max-width: 100%;
    div {
      max-width: 100%;
      box-shadow: rgb(0 0 0 / 10%) 1px 1px 8px;
      border-radius: 5px;
      background: rgb(255, 255, 255);
      -webkit-box-align: center;
      align-items: center;
      padding: 0px 1rem;
      margin-bottom: 16px;
      margin-right: 16px;
      min-height: 6rem;
      line-height: 1.25rem;
      border: 1px solid transparent;
      display: flex;
      flex-direction: row;
      flex-flow: row nowrap;
    }
`;