import { useState } from "react";
import ButtonConfirm from "../../components/ButtonConfirm";
import { Checkbox } from "../../FormRoot/Checkbox";
import { AnimationContainer, Container, Content } from "./styles"; // estilos pro import
import logo from "../../assets/logoLaura.png"

const Auth = ({ setValue, watch }) => {
    const [disabled, setDisabled] = useState(true);

    const handle = () => {
        setValue("auth", true)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }

    return (
        <>
            <Container>
                <Content>
                    <header>
                        <img src={logo} alt="Instituto Laura" />
                    </header>
                    <AnimationContainer>
                        <div>
                            <h2>Avalie seus sintomas e o risco de doença grave</h2>
                            <h4>
                                A partir das informações que relatar,
                                seguindo as diretrizes do Ministério da Saúde de março de 2020.<br></br>
                                O formulário a seguir não substitui um diagnóstico médico. Em caso de dúvida, fale com o seu médico e evite sair de casa.
                            </h4>
                            <div>
                                <Checkbox id="auth" name="auth" onChange={() => setDisabled(!disabled)} />
                                <label htmlFor="auth">
                                    Afirmo que li e aceito as <a href="https://lgpd.laura-br.com/pa/privacy.pdf">Políticas de Privacidade </a>
                                    e o <a href="https://institutolaura.s3.amazonaws.com/pdf/Termo%2Bde%2Bconsentimento.pdf">
                                        Termo de consentimento</a></label>
                            </div>

                        </div>
                    </AnimationContainer>
                    <div id="confirm">
                        <ButtonConfirm type="button" onClick={() => handle()} disabled={disabled}>Confirmar</ButtonConfirm>
                    </div>
                </Content>
            </Container>
        </>
    )
};


export default Auth;