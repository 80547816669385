import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const HowPainStarted = ({ setValue, watch, belly }) => {
  const severity = watch('severity') || 0
  const handle = (howPainStarted) => {
    setValue('howPainStarted', howPainStarted)
    if (severity < 3) {
      if (belly && howPainStarted === 'A dor começou de modo abrupto, de uma hora para outra') {
        setValue('severity', 3)
      }
    }
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }
  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Como começou a dor?</h2>
          <div>
            <Button type='button' onClick={() => handle('A dor começou de modo abrupto, de uma hora para outra')}>
              A dor começou de modo abrupto, de uma hora para outra
            </Button>
            <Button type='button' onClick={() => handle('A dor começou gradualmente')}>
              A dor começou gradualmente
            </Button>
            <Button type='button' onClick={() => handle('Nenhuma acima')}>
              Nenhuma acima
            </Button>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}

export default HowPainStarted
