import { AnimationContainer, Container, Content } from "./styles"; // estilos pro import

const FormAwnser = ({ severity }) => {

    const result = {
        red: "Com base em suas respostas, recomendamos que procure um serviço de Pronto Atendimento presencial ou aguarde um pouco para realizar uma teleconsulta de urgência, para receber orientações de um médico. Um atendente da equipe assistencial irá entrar em contato com você o mais rápido possível.",
        blue: "Com base em suas respostas, sugerimos que aguarde um pouco para realizar uma teleconsulta, onde irá receber orientações de um médico, se notar que seu estado de saúde não está melhorando, sugerimos que procure um serviço de Pronto Atendimento presencial. Um atendente da nossa equipe assistencial irá entrar em contato com você o mais rápido possível.",
        gray: "Com base em suas respostas, sugerimos que aguarde um pouco para realizar uma teleconsulta, onde irá receber orientações de um médico, se notar que seu estado de saúde não está melhorando, sugerimos que procure um serviço de Pronto Atendimento presencial. Um atendente da nossa equipe assistencial irá entrar em contato com você o mais rápido possível.",
        green: "Com base em suas respostas, sugerimos que aguarde um pouco para realizar uma teleconsulta, onde irá receber orientações de um médico, se notar que seu estado de saúde não está melhorando, sugerimos que procure um serviço de Pronto Atendimento presencial. Um atendente da nossa equipe assistencial irá entrar em contato com você o mais rápido possível.",
        yellow: "Com base em suas respostas, recomendamos que realize uma teleconsulta para receber orientações de um médico, ainda sugerimos que procure um serviço de Pronto Atendimento presencial. Um atendente da equipe assistencial irá entrar em contato com você o mais rápido possível.",
        waiting: "Identificamos que você já está na fila para atendimento. Caso seus sintomas tenham piorado, responda novamente a triagem."
    }

    return (
        <>
            <Container>
                <Content>
                    <AnimationContainer>
                        <div severity={severity}>
                            <h5>Resultado</h5>
                            <p>{result[severity]}</p>
                        </div>
                    </AnimationContainer>
                </Content>
            </Container>
        </>
    )
};


export default FormAwnser;