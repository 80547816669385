import Button from "../../components/Button";
import { AnimationContainer, Container, Content } from "./styles";

const Pregnancy = ({ setValue }) => {
    const handle = (isPregnant) => {
        setValue("isPregnant", isPregnant)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Você está grávida?
                    </h2>
                    <div>
                        <Button type="button" onClick={() => handle("Sim")}>Sim</Button>
                        <Button type="button" onClick={() => handle("Não")}>Não</Button>
                        <Button type="button" onClick={() => handle("Não sei")}>Não sei</Button>
                    </div>
                </AnimationContainer>
            </Content>
        </Container>
    )
};

export default Pregnancy;