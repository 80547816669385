import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px)        
    }
    to {
        opacity: 1;
        transform: translateX(0px)
    }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${appearFromLeft} 1s;
  h2 {
    margin: 0 0 29px;
  }
  @media (max-width: 768px) {
    h2 {
      font-size: 1rem;
    }
    div {
      width: 100%;
    }
    span {
      font-size: 0.75rem;
    }
    button {
      width: 75%;
    }
  }
`;
