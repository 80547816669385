import Button from '../../components/Button'
import { AnimationContainer, Container, Content } from './styles'

const Fever = ({ setValue, watch }) => {
  const severity = watch('severity') || 0
  
  const handle = (fever, severityWeight) => {
    if (severityWeight > severity) {
      setValue('severity', severityWeight)
    }
    setValue('fever', fever)
    setTimeout(() => {
      window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
    }, 200)
  }

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h2>Você apresenta febre?</h2>
          <div>
            <Button type='button' onClick={() => handle('Sim, acima de 37,8 graus', 4)}>
              Sim, acima de 37,8 graus
            </Button>
            <Button type='button' onClick={() => handle('Sim, mas não medi no termômetro', 4)}>
              Sim, mas não medi no termômetro
            </Button>
            <Button type='button' onClick={() => handle('Não', 2)}>
              Não
            </Button>
          </div>
        </AnimationContainer>
      </Content>
    </Container>
  )
}

export default Fever
