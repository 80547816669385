import { Container } from "./styles";

const Error = ({ label, icon: Icon, register, error, control, ...rest }) => {
  return (
    <Container>
      <div>
        {label} {!!error && <span> {error} </span>}
      </div>
    </Container>
  );
};

export default Error;
