import { useState } from "react";
import ButtonConfirm from "../../components/ButtonConfirm";
import { Checkbox } from "../../components/Checkbox";
import { Spinner } from "../../components/Spinner";
import { AnimationContainer, Container, Content } from "./styles";


const ChronicDisease = ({ setValue, onSubmit, getValues, isLoading, setIsLoading, watch }) => {
    const clientId = 2; // deixar dinamico via env
    const [comorbities, setComorbities] = useState([])
    const severity = watch('severity') || 0
    const [isDisabled, setIsDisabled] = useState(false);
    const handle = (comorbities) => {
        setIsDisabled(true);
        if (severity < 3 && comorbities.includes("Câncer sem uso recente de quimioterapia")) {
            setValue('severity', 3)
        }
        setIsLoading(true)
        setValue("comorbities", comorbities)
        setValue('clientId', clientId)
        const form = getValues()
        onSubmit(form)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }

    const handleWithClear = (comorbities) => {
        setIsDisabled(true);
        setIsLoading(true)
        setValue("comorbities", comorbities)
        for (let index = 1; index <= 19; index++) {
            const element = document.getElementById(`comorbities-${index}`);
            element.checked = false
        }
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
        setValue('clientId', clientId)
        const form = getValues()
        onSubmit(form)
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }

    const checkBoxState = (e, v) => {
        if (e.target?.checked) {
            setComorbities(state => [...state, v])
        }
        else {
            setComorbities(state => state.filter(e => e !== v))
        }
    }
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Você apresenta alguma doença crônica como:
                    </h2>
                    <div>
                        <Checkbox id="comorbities-1" label="Diabetes tipo 1" onChange={(e) => checkBoxState(e, "Diabetes tipo 1")} />
                        <Checkbox id="comorbities-2" label="Diabetes tipo 2" onChange={(e) => checkBoxState(e, "Diabetes tipo 2")} />
                        <Checkbox id="comorbities-3" label="Pressão alta" onChange={(e) => checkBoxState(e, "Pressão alta")} />
                        <Checkbox id="comorbities-4" label="Câncer com uso recente de quimioterapia" onChange={(e) => checkBoxState(e, "Câncer com uso recente de quimioterapia")} />
                        <Checkbox id="comorbities-5" label="Câncer sem uso recente de quimioterapia" onChange={(e) => checkBoxState(e, "Câncer sem uso recente de quimioterapia")} />
                        <Checkbox id="comorbities-6" label="Insuficiência Cardíaca" onChange={(e) => checkBoxState(e, "Insuficiência cardíaca")} />
                        <Checkbox id="comorbities-7" label="Arritmia" onChange={(e) => checkBoxState(e, "Arritmia")} />
                        <Checkbox id="comorbities-8" label="Infarto do coração" onChange={(e) => checkBoxState(e, "Infarto do coração")} />
                        <Checkbox id="comorbities-9" label="Acidente vascular cerebral (derrame)" onChange={(e) => checkBoxState(e, "Acidente vascular cerebral (derrame)")} />
                        <Checkbox id="comorbities-10" label="Doença neurológica crônica" onChange={(e) => checkBoxState(e, "Doença neurológica crônica")} />
                        <Checkbox id="comorbities-11" label="Asma" onChange={(e) => checkBoxState(e, "Asma")} />
                        <Checkbox id="comorbities-12" label="Enfisema pulmonar" onChange={(e) => checkBoxState(e, "Enfisema pulmonar")} />
                        <Checkbox id="comorbities-13" label="Outras doenças pulmonares" onChange={(e) => checkBoxState(e, "Outras doenças pulmonares")} />
                        <Checkbox id="comorbities-14" label="Doença do rim pré-dialítica ou em diálise" onChange={(e) => checkBoxState(e, "Doença do rim pré-dialítica ou em diálise")} />
                        <Checkbox id="comorbities-15" label="Doenças do fígado" onChange={(e) => checkBoxState(e, "Doenças do fígado")} />
                        <Checkbox id="comorbities-16" label="Deficiência do sistema imunológico" onChange={(e) => checkBoxState(e, "Deficiência do sistema imunológico")} />
                        <Checkbox id="comorbities-17" label="História de úlcera gástrica" onChange={(e) => checkBoxState(e, "História de úlcera gástrica")} />
                        <Checkbox id="comorbities-18" label="Doenças cromossômicas" onChange={(e) => checkBoxState(e, "Doenças cromossômicas")} />
                        <Checkbox id="comorbities-19" label="Outras" onChange={(e) => checkBoxState(e, "Outras")} />
                        <ButtonConfirm type="button" disabled={isDisabled} onClick={() => handleWithClear(["Nenhuma comorbidade"])}>Nenhuma comorbidade</ButtonConfirm>
                        <div id="submit">
                            <ButtonConfirm type="button" disabled={isDisabled} onClick={() => { if ((comorbities.length) > 0) handle(comorbities) }}>Confirmar</ButtonConfirm>
                            {isLoading && <Spinner />}
                        </div>
                            
                    </div>
                </AnimationContainer>
            </Content>

        </Container>
    )
};

export default ChronicDisease;