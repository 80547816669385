import Button from "../../components/Button";
import { AnimationContainer, Container, Content } from "./styles";

const Travelled = ({ setValue, watch }) => {
    const severity = watch('severity')
    const handle = (bool, weight) => {
        setValue("travelled", bool)
        if (severity < weight) {
            setValue('severity', weight)
        }
        setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
        }, 200);
    }
    return (
        <Container>
            <Content>
                <AnimationContainer>
                    <h2>
                        Você viajou nos últimos 14 dias?
                    </h2>
                    <div>
                        <Button type="button" onClick={() => handle("Sim", 2)}>Sim</Button>
                        <Button type="button" onClick={() => handle("Não", 0)}>Não</Button>
                    </div>
                </AnimationContainer>
            </Content>

        </Container>
    )
};

export default Travelled;